import React, { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "./InspectPdf.module.css";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useAllProduct } from "../../../api-context/commonApiContext";
import { Spin } from "antd";

const InspectPdf = ({ buttonClicked, pdfUrl, setPdfUrl }) => {
  const [items, setItems] = useState([]);
  const [imageData, setImageData] = useState({});
  const [draggables, setDraggables] = useState([]);
  const [id, setId] = useState("");
  const [doc_id, setDoc_id] = useState("");
  const containerRefs = useRef({});
  const [imageSizes, setImageSizes] = useState({}); // Store image dimensions
  const [ogDoc, setOgDoc] = useState("");
  const [data, setData] = useState();
  const [workFlowId, setWorkFlowId] = useState("");
  // const [authToken, setAuthToken] = useState("");
  const [docName, setDocName] = useState("");
  const [base64FileData, setBase64FileData] = useState("");
  const { loader, setLoader, authTokens } = useAllProduct();
  useEffect(() => {
    setId(companyStore.companyID);
    setDoc_id(companyStore.doc_id);
    console.log("doc_id", doc_id);
    console.log("previewing documents");

    setLoader(true);
    if (Object.keys(authTokens)?.length > 0) {
      axios
        .post(
          `${config.node_api_endpoint}/v1/esign/getdocument`,
          { doc_id: companyStore.doc_id },
          {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              aws_cognito_access_token: authTokens.accessToken,
              // "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          console.log("res---",res);
          
          setLoader(false);
          console.log(res.data);
          setData(res.data.signing_parties);
          setImageData(res.data.png_url);
          console.log("imagedata-->", res.data.png_url);
          setOgDoc(res.data.png_url);
          setWorkFlowId(res.data.workflowId);
          // setAuthToken(res.data.authToken);
        })
        .catch((er) => {
          setLoader(false);
          "%o", er;
        });
    }
  }, [authTokens,companyStore]);
console.log("sas",authTokens);

  useEffect(() => {
    console.log("workflowId",workFlowId);
    
    if (workFlowId) {
      setLoader(true);
      axios
        .post(`${config.node_api_endpoint}/v1/esign/DownloadWorkflowDocuments`, {
          workFlowId: workFlowId,
          // authToken: authToken,
        })
        .then((res) => {
          setLoader(false);
          console.log("Document preview-->", res?.data?.Response[0]?.DocumentName);
          setBase64FileData(res?.data?.Response[0]?.Base64FileData);
          setDocName(res?.data?.Response[0]?.DocumentName);
          const pdfBlob = base64ToBlob(res?.data?.Response[0]?.Base64FileData, "application/pdf");
          const pdfFileUrl = URL.createObjectURL(pdfBlob);
          setPdfUrl(pdfFileUrl);
        })
        .catch((err) => {
          setLoader(false);
          console.log("%o", err);
        });
    }
  }, [workFlowId]);

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64); // Decode base64 string to binary
    const byteArrays = [];

    // Create byte arrays from the binary string
    for (let offset = 0; offset < byteCharacters?.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice?.length);
      for (let i = 0; i < slice?.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }

    // Create the Blob with the PDF MIME type
    return new Blob(byteArrays, { type: mimeType });
  };

  function digito2EsctackCordinates(dlx, dly, dux, duy) {
    var c1 = 1.423636485;
    var c2 = -7.422450698;
    var c3 = -1.982936128;
    var c4 = 24.98120326;

    var eslx = dlx * c1 + dly * c3;
    var esly = dlx * c2 + dly * c4;
    var esux = dux * c1 + duy * c3;
    var esuy = dux * c2 + duy * c4;
    return {
      lx: eslx,
      ly: esly,
      ux: esux,
      uy: esuy,
    };
  }

  const coordinates = [
    { x: 300, y: 540 },
    { x: 180, y: 540 },
    { x: 60, y: 540 },
    { x: 300, y: 513 },
    { x: 180, y: 513 },
    { x: 60, y: 513 },
  ];

  const handleButtonClick = (item) => {
    console.log([
      ...draggables,
      { id: item.id, name: item.name, email: item.email, x: 0, y: 0, imageId: item.imageId },
    ]);
  };

  const handleDrag = (index, e, data) => {
    setDraggables((prev) => prev.map((item, i) => (i === index ? { ...item, x: data.x, y: data.y } : item)));
  };

  const handleImageLoad = (e, imageId) => {
    const imgElement = e.target;
    setImageSizes((prev) => ({
      ...prev,
      [imageId]: {
        width: imgElement.naturalWidth / 4,
        height: imgElement.naturalHeight / 4,
      },
    }));
  };

  function esStackCordinatesToDigio(x, y) {
    console.log("in function");

    var elx = x;
    var ely = 1;
    var eux = y; //because tranformation matrix was derived with y in eux position by mistake
    var euy = 1;
    var c1 = 0.583297106247098;
    var c2 = 0.166639121;
    var c3 = 274.3495435;
    var c4 = -39.985987035;

    var dlx = 449.338661360347 - (300 - x) * 1.41666974191992;
    var dly = 10.0057451431493 - (540 - y) * -1.1854809;
    var dux = 589.329955680173 - (449.338661360347 - dlx);
    var duy = 49.9991308793456 - (10.0057451431493 - dly);
    return {
      lx: dlx,
      ly: dly,
      ux: dux,
      uy: duy,
    };
  }

  const handleSendClick = () => {
    console.log("in it");
    console.log(draggables);

    const updatedData = draggables
      .map((item) => {
        console.log("items ", item.x, item.y);

        let coordinate = esStackCordinatesToDigio(item.x, item.y);
        // console.log("hey");
        console.log("coordinates", coordinate);

        const llx = coordinate.lx;
        const lly = coordinate.ly;
        const urx = coordinate.ux;
        const ury = coordinate.uy;

        return {
          name: item.name,
          email: item.email,
          position: {
            llx: llx,
            lly: lly,
            urx: urx,
            ury: ury,
          },
        };
      })
      .filter((data) => data !== null);

    console.log("updated data", updatedData);
  };

  if (buttonClicked) {
    console.log("inclicked");

    handleSendClick();
  }
  const disableContextMenu = (e) => e.preventDefault();

  console.log("pdfUrl-->", pdfUrl);

  return (
    // <div onContextMenu={disableContextMenu}>
    //   <iframe src={pdfUrl} width="100%" height="1000px" title="Document Preview"></iframe>
    // </div>
    // <Spin
    //   size="large"
    //   tip="Loading..."
    //   spinning={loader}
    //   className="d-flex justify-content-center align-items-center"
    //   // style={{ maxHeight: "none" }}
    // >
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      {pdfUrl?.length > 0 ? <Viewer fileUrl={pdfUrl} /> : ""}
    </Worker>
  );
};

export default InspectPdf;
