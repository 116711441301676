import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import styles from "./MakeEditor.module.css";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
import { useAllProduct } from "../../../../api-context/commonApiContext";

const MakeEditor = ({
  isVisible,
  onclose,
  setClicked,
  makeEditor,
  contract_id,
  escrow_id,
  email,
  count,
  setIsSuccess,
}) => {
  const { authTokens, authFromUrl } = useAllProduct();
  const [users, setUsers] = useState([]);
  const [selectedActions, setSelectedActions] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currenteditor, setcurrenteditor] = useState("");
  const [data, setData] = useState({});
  const [doc_link, setDocLink] = useState("");
  const formData = new FormData();
  const modalRef = useRef(null);
  const [currentEditAccess, setCurrentEditAccess] = useState("");
  const [previousCount, setPreviousCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    const obj = {
      escrow_id: companyStore?.escrowId,
      contract_id: companyStore?.Contract_id,
    };
    console.log("authTokens-->", authTokens, authFromUrl);

    axios
      .post(`${config.mock_api_endpoint}/api/getContractData`, obj, {
        // headers: {
        //   // aws_cognito_id_token: authTokens?.idToken,
        //   aws_cognito_access_token: authFromUrl?.length > 0 ? authFromUrl : authTokens?.accessToken,
        //   "Content-Type": "application/json",
        // },
        headers: {
          "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setData(res.data);
        let accessNotRemoved = res.data.counter_parties.filter((data) => data.status !== "Removed");
        setUsers(accessNotRemoved);
        const initialActions = {};
        res.data.counter_parties.forEach((user) => {
          initialActions[user._id] = /* user.access || */ "view";
        });
        setSelectedActions(initialActions);
        setCurrentEditAccess(res.data.current_edit_access);
        setPreviousCount(res.data.changes_count);
      })
      .catch((err) => {
        console.error("%o", err);
        setError("Failed to load data");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isVisible, companyStore, authTokens, authFromUrl]);

  const handleActionChange = (userId, access) => {
    setSelectedActions((prev) => {
      const newActions = { ...prev }; // Clone the previous state

      if (access === "edit") {
        // Set all other users to "view"
        Object.keys(newActions).forEach((id) => {
          if (id !== userId) {
            newActions[id] = "view";
          } else {
            setcurrenteditor(userId);
            newActions[id] = "edit"; // Set selected user to "edit"
          }
        });
      } else {
        newActions[userId] = access; // If access is not "edit", just set the user access
      }

      return newActions;
    });
  };

  const handleSubmit = () => {
    // console.log("users-->", selectedActions);
    var currentuser = "";
    // // Map through users and select actions
    let updatedActions = users.map((user) => {
      return {
        email: user.email,
        access: selectedActions[user._id] || "view",
      };
    });

    // Process the actions if "edit" access exists
    updatedActions.map((user) => {
      if (user.access === "edit") {
        setcurrenteditor(user.email); // Set the current editor
        currentuser = user.email;
        setDocLink(user.doc_link); // Set the document link
      }
    });

    // Prepare the payload to be sent to the backend
    const payload = {
      escrow_id: companyStore.escrowId,
      contract_id: companyStore.Contract_id,
      current_edit_access: currentuser,
      changes_count: count,
      previous_count: previousCount,
    };

    // Submit the data to modify access

    axios
      .post(`${config.mock_api_endpoint}/api/modifyAccess`, payload, {
        headers: {
          "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
          // "Content-Type": "application/json",
        },
      })
      .then((res) => {
        onclose();
        // setClicked(true);
        // For each counter party, prepare and send an email
        res.data.updatedContract.counter_parties.forEach((rres) => {
          let body = `
            <html>
              <head>
                <style>
                  body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
                  .container { max-width: 600px; margin: auto; padding: 20px; }
                  h1 { color: #007BFF; }
                  a { color: #007BFF; text-decoration: none; }
                  .button { display: inline-block; padding: 10px 20px; font-size: 16px; color: white; background-color: #007BFF; border: none; border-radius: 5px; text-decoration: none; text-align: center; }
                  .footer { margin-top: 20px; font-size: 0.9em; color: #777; }
                </style>
              </head>
              <body>
                <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
                <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
                  <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 32px; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
                    <tr>
                      <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
                        <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                          <tr>
                            <td align="center" style="padding-bottom: 13.76px">
                              <img src="assets/image_1885faaf.png" width="101" style="max-width: initial; width: 101px; display: block" />
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding-top: 13.76px; padding-bottom: 13.72px; padding-left: 32px">
                              <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${rres.name},</span></p>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding-top: 13.72px; padding-bottom: 15.16px; padding-left: 32px; padding-right: 32px">
                              <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%"><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left">You have been assigned as an </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">Editor</span><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left"> for the contract titled</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${data.contract_name} &quot;.</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span class="color-777777" style="font-size: 20px; font-weight: 500; color: #777777; letter-spacing: 0.4px; text-align: left"> As an editor, you can review and make changes to the document directly through our document editor. </span></p>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" style="padding-top: 15.16px; padding-bottom: 7.58px; padding-left: 32px">
                              <p width="100.00%" style="font-size: 20px; font-weight: 700; letter-spacing: 0.4px; color: #333333; margin: 0; padding: 0; width: 100%; line-height: 15.68px; mso-line-height-rule: exactly">Please follow the link below to edit the contract:</p>
                            </td>
                          </tr>
                          <tr>
                            <td style="padding-top: 7.58px; padding-left: 33px; padding-right: 33px">
                              <table class="bg-2348ab" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="#2348AB" width="591.00" height="40.00" style="border-radius: 30px; background-color: #2348AB; width: 591px; height: 40px; border-spacing: 0; border-collapse: separate">
                                <tr>
                                  <td valign="middle" width="100.00%" height="40.00" style="width: 100%; vertical-align: middle; height: 40px">
                                    <a href="${config.doc_link_api_endpoint}${rres.doc_link}" style="text-decoration: none;">
                                      <p class="color-fffffe" width="100.00%" style="font-size: 16px; font-weight: 700; letter-spacing: 0.15px; color: white; margin: 0; padding: 0; width: 100%; line-height: 24px; text-align: center; mso-line-height-alt: 24px">
                                        Edit Contract
                                      </p>
                                    </a>
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </center>
              </body>
            </html>
          `;

          // Send email if the user is the editor

          let payload2 = {
            contract_id: contract_id,
            escrow_id: escrow_id,
            changes_count: count,
            previous_count: previousCount,
            email: email,
            status: "Finalized",
          };
          console.log("makeEditor-->", makeEditor);

          if (!makeEditor) {
            console.log("makeEditor222-->");
            axios
              .post(`${config.mock_api_endpoint}/api/saveAndSend`, payload2, {
                headers: {
                  "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
                  // "Content-Type": "application/json",
                },
              })
              .then((res) => {
                console.log(res);
                setClicked(true);
                setIsSuccess(true);
              })
              .catch((err) => {
                console.log(err);
              });
          }

          if (rres.email === currentuser) {
            const emailPayload = {
              to: currentuser,
              body: body,
              subject: `Access and Edit Contract - ${data.contract_name}`,
            };
            axios
              .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
                },
              })
              .then((response) => {
                setClicked(true);
                console.log("Email sent successfully:", response);
                setIsSuccess(true);
              })
              .catch((error) => {
                console.error("Error sending email:", error);
              });
          }
        });
      })
      .catch((error) => {
        console.error("Error modifying access:", error);
      });
  };

  if (!isVisible) return null;

  return (
    <div className={styles.makeEditor}>
      <div className={styles.makeEditorInner} ref={modalRef}>
        <div className={styles.frameParent}>
          <div className={styles.makeEditorParent}>
            <div className={styles.makeEditor1}>Make Edit</div>
            <img className={styles.xCircleIcon} alt="" src="/xcircle.svg" onClick={onclose} />
          </div>

          <TableContainer component={Paper} className={styles.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Email</b>
                  </TableCell>
                  <TableCell>
                    <b>Access</b>
                  </TableCell>
                  {/* <TableCell><b>Action</b></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {!makeEditor
                  ? users
                      .filter((user) => !(user.email === currentEditAccess))
                      .map((user) => (
                        <TableRow key={user._id}>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              {/* <InputLabel>Select Access</InputLabel> */}
                              <Select
                                value={selectedActions[user._id]}
                                onChange={(event) => handleActionChange(user._id, event.target.value)}
                                disabled={
                                  !makeEditor && user.email === currentEditAccess // Disable if not current editor
                                }
                              >
                                <MenuItem value="view">View</MenuItem>
                                <MenuItem value="edit">Edit</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))
                  : users.map((user) => (
                      <TableRow key={user._id}>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          <FormControl fullWidth>
                            {/* <InputLabel>Select Access</InputLabel> */}
                            <Select
                              value={selectedActions[user._id]}
                              onChange={(event) => handleActionChange(user._id, event.target.value)}
                              // disabled={
                              //   !makeEditor && user.email === currentEditAccess // Disable if not current editor
                              // }
                            >
                              <MenuItem value="view">View</MenuItem>
                              <MenuItem value="edit">Edit</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          <div className={styles.actionsContainer}>
            <button onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

MakeEditor.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
};

export default MakeEditor;
