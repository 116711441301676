import PropTypes from "prop-types";
import styles from "./EsignMain.module.css";
import { Fragment, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../service/config";
import DeleteEsignRequest from "./DeleteEsignRequest/DeleteEsignRequest";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Button,
  TablePagination,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import companyStore from "../CompanyStore";
import { Pagination } from "antd";
import { DatePicker } from "@mui/x-date-pickers";
import { useAllProduct } from "../../api-context/commonApiContext";

const EsignMain = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const [arr, setArr] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [compName, setCompName] = useState("");
  const [escrowName, setEscrowName] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [docId, setdocId] = useState("");
  const [id, setID] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { role, authTokens } = useAllProduct();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // useEffect(() => {
  //   companyStore.setContractID("");
  //   console.log("esign main");
  //   setID(companyStore.companyID);
  //   console.log("company id=", id);
  //   setCompName(companyStore.escrowName);
  //   setEscrowName(companyStore.escrowName);
  //   let payload = {
  //     escrow_id: companyStore.escrowId,
  //   };

  //   axios
  //     .post(`${config.node_api_endpoint}/v2/esign/listdocuments`, payload)
  //     .then((res) => {
  //       setData(res.data);
  //       setFilteredData(res.data.reverse());
  //       console.log("esign doc id List Document", res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      companyStore.setContractID("");
      console.log("esign main");
      setID(companyStore.companyID);
      console.log("company id=", id);
      setCompName(companyStore.escrowName);
      setEscrowName(companyStore.escrowName);
      let payload = {
        escrow_id: companyStore.escrowId,
      };
      try {
        // Fetch KYC list data
        const signRes = await axios.post(`${config.node_api_endpoint}/v2/esign/listdocuments`, payload, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            aws_cognito_access_token: authTokens.accessToken,
            "Content-Type": "application/json",
          },
        });
        const signData = signRes.data;
        console.log("sign-->", signData);

        // Fetch user data
        const userRes = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            aws_cognito_access_token: authTokens.accessToken,
            "Content-Type": "application/json",
          },
        });
        const users = userRes.data.users;

        // Map roles to KYC data
        const dataWithRoles = signData.map((signItem) => {
          const user = users.find((u) => u.email === signItem.created_by);
          return {
            ...signItem,
            role: user ? user.auth[0].role : "Unknown",
          };
        });

        const sortedData = dataWithRoles.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at); // Sorting in descending order
        });

        setData(sortedData); // Original data without roles
        setFilteredData(sortedData); // Data enriched with roles and sorted
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (Object.keys(authTokens).length > 0) fetchData();
  }, [authTokens]);

  const navigate = useNavigate();

  const resetFilters = () => {
    setFilteredData(data);
  };

  const applyFilters = () => {
    let filtered = [...data];

    if (textFilter) {
      filtered = filtered.filter((item) => item.agreement_name.toLowerCase().includes(textFilter.toLowerCase()));
    }

    if (statusFilter) {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    // if (createdDateFilter) {
    //   filtered = filtered.filter(
    //     (item) => new Date(item.created_at).toDateString() === new Date(createdDateFilter).toDateString(),
    //   );
    // }
    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        console.log("dateFilter--->", itemDate, filterDate);

        return itemDate === filterDate;
      });
    }

    setFilteredData(filtered); // Update the filtered data state
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item?.agreement_name?.toLowerCase().includes(value.toLowerCase()) ||
          item?.created_at?.toLowerCase().includes(value.toLowerCase()) ||
          item?.created_by?.toLowerCase().includes(value.toLowerCase()) ||
          item?.role[0]?.toLowerCase().includes(value.toLowerCase()) ||
          item?.status?.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const startIndex = (currentPage - 1) * pageSize;
  // const currentPageData = filteredData.slice(startIndex, startIndex + pageSize);
  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

  return (
    <Fragment>
      <div className={`${styles.pageContainer} ${styles.family}  h-100 w-100`}>
        <div className={styles.frameParent}>
          <div className={styles.escrowstackPrivateLimitedParent}>
            <b className={`${styles.escrowName} mt-4 mb-3`}>{escrowName}</b>

            <button
              className={`${styles.loadsButtons} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Maker" ? "" : styles.disabledDiv}`}
              onClick={() => {
                navigate(`/content/esign/createesign`);
              }}
            >
              <img className={styles.plusCircleIcon} alt="" src="/pluscircle2.svg" />
              <b className={styles.newKycRequest}>New E-sign Request</b>
            </button>
          </div>
          <div className={`${styles.frameWrapper} p-2 mt-2 mb-2`}>
            <div className={styles.frameGroup}>
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <img className={styles.leadingIcon} alt="" src="/leadingicon3.svg" />
                  <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
                </div>
              </div>

              <div className={`${styles.mobileMenuParent}`}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{ "& .MuiInputBase-root": { width: "250px", borderRadius: "12px" } }}
                  />
                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "250px",
                        borderRadius: "12px",
                      },
                    }}
                  />
                </LocalizationProvider>

                <FormControl sx={{ width: "250px" }}>
                  <InputLabel id="escrow-select-label">Status</InputLabel>
                  <Select
                    labelId="escrow-select-label"
                    id="escrow-select"
                    value={statusFilter}
                    label="Status"
                    onChange={(e) => setStatusFilter(e.target.value)}
                    sx={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"draft"}>Draft</MenuItem>
                    <MenuItem value={"requested"}>Requested</MenuItem>
                    <MenuItem value={"Completed"}>Completed</MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"expired"}>Expired</MenuItem>
                  </Select>
                </FormControl>

                {/* Button to apply filter */}
                <div className={styles.loadsButtons1} onClick={applyFilters}>
                  <b className={styles.escrowstackPrivateLimited}>Apply</b>
                </div>
              </div>
            </div>
          </div>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead
                  className={styles.tableBg}
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: "16px",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell align="center">Name Of Agreement</TableCell>
                    <TableCell align="center">Last Updated</TableCell>
                    <TableCell align="center">Created By</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.length > 0 ? (
                    currentPageData.map((detail, index) => (
                      <TableRow key={index} sx={{ "& .MuiTableCell-root": { padding: "8px" } }}>
                        <TableCell align="center">{detail.agreement_name}</TableCell>
                        <TableCell align="center">{detail.created_at.split("T")[0]}</TableCell>
                        <TableCell align="center">
                          {detail.created_by?.length > 0 ? detail.created_by : "N/A"}
                        </TableCell>
                        <TableCell align="center">{detail.role?.length > 0 ? detail.role : "N/A"}</TableCell>
                        <TableCell align="center">{detail?.status?.toUpperCase() || ""}</TableCell>
                        <TableCell align="center">
                          {/* <Button
                            className={styles.change}
                            style={{ color: "#2348AB" }}
                            onClick={() => {
                              handleScrollToTop();
                              setShowActivity(true);
                              setCurrentCompId(detail._id);
                              setCurrentCompName(detail.name);
                            }}
                          >
                            {"View More"}
                          </Button> */}
                          <Button
                            className={styles.btn} /*view Request Button*/
                            onClick={() => {
                              setdocId(detail._id);
                              companyStore.setDoc_id(detail._id);
                              navigate(`/content/esign/preview/doc`);
                            }}
                            //[0] is docid, [1] is company id
                          >
                            <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                          </Button>
                          <b className={styles.b}>/</b>
                          <Button
                            className={styles.btn} /*Delete Request Button*/
                            onClick={() => {
                              setShowModel(true);
                              setdocId(detail._id);
                              console.log("docid", detail._id);
                              companyStore.setDoc_id(detail._id);
                            }}
                          >
                            <img className={styles.eyeIcon} alt="" src="/trash-01.svg" />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No Records Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className={`${styles.tableBg}`}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      <DeleteEsignRequest
        isVisible={showModel}
        onclose={() => {
          setShowModel(false);
          // window.location.reload();
        }}
        Docid={docId}
      />
    </Fragment>
  );
};

EsignMain.propTypes = {
  className: PropTypes.string,
};

export default EsignMain;
