import DocumentEditor from "@txtextcontrol/tx-react-ds-document-editor";
import axios from "axios";
import config from "../../../../service/config.js";
import { useEffect, useState } from "react";
import companyStore from "../../CompanyStore.js";
import style from "./EditorView.module.css";
import FileInput from "../AdminView/FileInput.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Buffer } from "buffer";
import MakeEditor from "../AdminView/makeEditor/MakeEditor.js";
import "../AdminView/DocumentEditor.css";
import { Spin } from "antd";
import { remove } from "mobx";
import { useAllProduct } from "../../../api-context/commonApiContext.js";
import ContractSuccess from "../SuccessView/ContractSuccess.js";
const CryptoJS = require("crypto-js");
function EditorView() {
  const { authTokens, authFromUrl } = useAllProduct();
  const [data, setData] = useState({});
  const [urldata, setUrl] = useState("");
  const [contname, setContName] = useState("");
  const [counter_parties, setCounterparty] = useState([]);
  const [usertype, setuserType] = useState("view");
  const location = useLocation();
  const [clickedEdit, setClickedEdit] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [hideDoc, setHideDoc] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const getTokenFromURL = () => {
    const query = new URLSearchParams(location.search);
    return query.get("token");
  };

  const token = getTokenFromURL();
  const decodedString = Buffer.from(token, "base64").toString("utf-8");

  const [email, setmain] = useState(decodedString.split("|")[0]);
  const [contractid, setcontractid] = useState(decodedString.split("|")[1]);
  const [Escrowid, setEscrowid] = useState(decodedString.split("|")[2]);
  const [isSuccess, setIsSuccess] = useState(false);
  console.log("getAuthFromUrl", authFromUrl);

  const check = () => {
    data.map((detail) => {
      if (email === detail.email_id) {
        if (action == "view") {
          setuserType("view");
        } else {
          setuserType("edit");
        }
      }
    });
  };

  const sendrequest = () => {
    const userMail = decodedString.split("|")[0];
    const userData = data.counter_parties.find((party) => party.email === userMail);
    console.log("userData-->", userData);

    let bodyHtml = `
   <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
  <head>
    <style type="text/css">
      @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700");
    </style>
    <title></title>
    <!--[if (!mso)&(!ie)]>These<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>are<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>for<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>outlook<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>live<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>that<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>removes<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>the first<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
    <!--<![endif]-->
    <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
    <!--<![endif]-->
    <!--[if gte mso 9]>
      <xml>
        <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
          <o:AllowPNG />
          <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
      </xml>
    <![endif]-->
    <style type="text/css">
      .dark-mode .bg-fffffe {
        background-color: #FFFFFE !important;
      }
      .dark-mode .color-4c4e64 {
        color: #4C4E64 !important;
      }
      .dark-mode .color-777777 {
        color: #777777 !important;
      }
      @media (prefers-color-scheme: dark) {
        html:not(.light-mode) .bg-fffffe {
          background-color: #FFFFFE !important;
        }
        html:not(.light-mode) .color-4c4e64 {
          color: #4C4E64 !important;
        }
        html:not(.light-mode) .color-777777 {
          color: #777777 !important;
        }
      }
      [data-ogsc] .bg-fffffe {
        background-color: #FFFFFE !important;
      }
      [data-ogsc] .color-4c4e64 {
        color: #4C4E64 !important;
      }
      [data-ogsc] .color-777777 {
        color: #777777 !important;
      }
    </style>
    <meta name="color-scheme" content="light dark" />
    <meta name="supported-color-schemes" content="light dark" />
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <!--[if !mso]><!-->
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <!--<![endif]-->
    <meta name="x-apple-disable-message-reformatting" />
    <style></style>
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <style type="text/css">
      u + div .kombai-email-compat__list-with-padding-left {
        padding-left: 0.5em !important;
      }
    </style>
    <!--[if mso]>
      <style type="text/css">
        v\:* {
          behavior: url(#default#VML);
          display: inline-block;
        }
        o\:* {
          behavior: url(#default#VML);
          display: inline-block;
        }
        w\:* {
          behavior: url(#default#VML);
          display: inline-block;
        }
        .ExternalClass {
          width: 100%;
        }
        table {
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
        }
        img {
          -ms-interpolation-mode: bicubic;
        }
        .ReadMsgBody {
          width: 100%;
        }
        a {
          background: transparent !important;
          background-color: transparent !important;
        }
        li {
          text-align: -webkit-match-parent;
          display: list-item;
          text-indent: -1em;
        }
        ul,
        ol {
          margin-left: 1em !important;
        }
        p {
          text-indent: 0;
        }
      </style>
    <![endif]-->
  </head>
  <body style="margin: 0; padding: 0">
    <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
    <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
      <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
        <tr>
          <td valign="top" width="100.00%" style="padding-top: 32px; padding-bottom: 27.16px; width: 100%; vertical-align: top">
            <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
              <tr>
                <td align="center" style="padding-bottom: 13.76px">
                  <img src="assets/image_1fad596c.png" width="101" style="max-width: initial; width: 101px; display: block" />
                </td>
              </tr>
              <tr>
                <td align="left" style="padding-top: 13.76px; padding-bottom: 11.72px; padding-left: 32px">
                  <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">Admin,</span></p>
                </td>
              </tr>
              <tr>
                <td align="left" style="padding-top: 11.72px; padding-left: 32px; padding-right: 32px">
                  <div class="color-777777" width="100.00%" style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                    <span>You have received a request from </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${userData?.name}</span><span> to gain edit access to the contract titled </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${contname} &quot;.</span><span> The current role assigned to this counterparty is </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">Viewer.<br /></span><span><br />Please review the request and take the appropriate action to grant or deny edit access.<br /><br />Contract Details:</span>
                    <ul class="kombai-email-compat__list-with-padding-left" style="list-style-type: disc; margin: 0; padding-left: 1.5em">
                      <li><span>Contract Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${contname}</span></li>
                      <li><span>Counterparty Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">(${userData?.name})</span></li>
                      <li><span>Counterparty Email: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">(${decodedString.split("|")[0]})</span></li>
                    </ul>
                  </div>
                </td>
              </tr>
                 <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
            </table>
          </td>
        </tr>
      </table>
    </center>
  </body>
</html>
`;

    const emailPayload = {
      to: data?.admin_email,
      body: bodyHtml,
      subject: `Edit Access Request for Contract: ${contname}`,
    };

    axios
      .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
      })
      .then((res) => {
        console.log(res.data);
        setClicked(true);
      })
      .catch((err) => {
        console.log("", err);
      });
  };

  const setFinalize = () => {
    let payload = {
      contract_id: decodedString.split("|")[1],
      escrow_id: decodedString.split("|")[2],
      email: decodedString.split("|")[0],
      status: "Finalized",
    };
    axios
      .post(`${config.mock_api_endpoint}/api/finalizedByViewer`, payload, {
        headers: {
          "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
          // "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        let bodyHtml = `
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
     <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
       <head>
         <style type="text/css">
           @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700");
         </style>
         <title></title>
         <!--[if (!mso)&(!ie)]>These<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>are<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>for<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>outlook<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>live<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>that<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>removes<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>the first<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
         <!--<![endif]-->
         <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
         <!--<![endif]-->
         <!--[if gte mso 9]>
           <xml>
             <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
               <o:AllowPNG />
               <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
           </xml>
         <![endif]-->
         <style type="text/css">
           .dark-mode .bg-fffffe {
             background-color: #FFFFFE !important;
           }
           .dark-mode .color-4c4e64 {
             color: #4C4E64 !important;
           }
           .dark-mode .color-777777 {
             color: #777777 !important;
           }
           @media (prefers-color-scheme: dark) {
             html:not(.light-mode) .bg-fffffe {
               background-color: #FFFFFE !important;
             }
             html:not(.light-mode) .color-4c4e64 {
               color: #4C4E64 !important;
             }
             html:not(.light-mode) .color-777777 {
               color: #777777 !important;
             }
           }
           [data-ogsc] .bg-fffffe {
             background-color: #FFFFFE !important;
           }
           [data-ogsc] .color-4c4e64 {
             color: #4C4E64 !important;
           }
           [data-ogsc] .color-777777 {
             color: #777777 !important;
           }
         </style>
         <meta name="color-scheme" content="light dark" />
         <meta name="supported-color-schemes" content="light dark" />
         <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
         <!--[if !mso]><!-->
         <meta http-equiv="X-UA-Compatible" content="IE=edge" />
         <!--<![endif]-->
         <meta name="x-apple-disable-message-reformatting" />
         <style></style>
         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
         <style type="text/css">
           u + div .kombai-email-compat__list-with-padding-left {
             padding-left: 0.5em !important;
           }
         </style>
         <!--[if mso]>
           <style type="text/css">
             v\:* {
               behavior: url(#default#VML);
               display: inline-block;
             }
             o\:* {
               behavior: url(#default#VML);
               display: inline-block;
             }
             w\:* {
               behavior: url(#default#VML);
               display: inline-block;
             }
             .ExternalClass {
               width: 100%;
             }
             table {
               mso-table-lspace: 0pt;
               mso-table-rspace: 0pt;
             }
             img {
               -ms-interpolation-mode: bicubic;
             }
             .ReadMsgBody {
               width: 100%;
             }
             a {
               background: transparent !important;
               background-color: transparent !important;
             }
             li {
               text-align: -webkit-match-parent;
               display: list-item;
               text-indent: -1em;
             }
             ul,
             ol {
               margin-left: 1em !important;
             }
             p {
               text-indent: 0;
             }
           </style>
         <![endif]-->
       </head>
       <body style="margin: 0; padding: 0">
         <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
         <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
           <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
             <tr>
               <td valign="top" width="100.00%" style="padding-top: 32px; padding-bottom: 27.28px; width: 100%; vertical-align: top">
                 <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                   <tr>
                     <td align="center" style="padding-bottom: 13.76px">
                       <img src="assets/image_1fad596c.png" width="101" style="max-width: initial; width: 101px; display: block" />
                     </td>
                   </tr>
                   <tr>
                     <td align="left" style="padding-top: 13.76px; padding-bottom: 11.78px; padding-left: 32px">
                       <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">Admin,</span></p>
                     </td>
                   </tr>
                   <tr>
                     <td align="left" style="padding-top: 11.78px; padding-left: 32px; padding-right: 32px">
                       <div class="color-777777" width="100.00%" style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                         <span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${decodedString.split("|")[0].split("@")[0]}</span><span> has finalized their changes to the contract titled </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${contname} &quot;.</span><span> Please review the proposed changes at your earliest convenience.<br /><br />Contract Details:</span>
                         <ul class="kombai-email-compat__list-with-padding-left" style="list-style-type: disc; margin: 0; padding-left: 1.5em">
                           <li><span>Contract Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${contname}</span></li>
                           <li><span>Counterparty Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${decodedString.split("|")[0].split("@")[0]} (${decodedString.split("|")[0]})</span></li>
                           <li><span>Status:</span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left"> Changes Finalised</span></li>
                         </ul>
                          <span> Once reviewed, you can accept the changes to move forward with negotiation process.</span>
                          </div>
                     </td>
                   </tr>
                     <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
                 </table>
               </td>
             </tr>
           </table>
         </center>
       </body>
     </html>
        `;

        const emailPayload2 = {
          to: companyStore?.AdminEmail,
          body: bodyHtml,
          subject: `Contract Changes Finalised by ${decodedString.split("|")[0].split("@")[0]}`,
        };

        // formData.append("subject", "Request Editor");
        axios
          .post(`${config.email_api_endpoint}/send-email`, emailPayload2, {
            headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
          })
          .then((res) => {
            console.log(res.data);
            setClicked(true);
          })
          .catch((err) => {
            console.log(err);
          });

        let bodyHtml2 = `
          <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
       <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
         <head>
           <style type="text/css">
             @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700");
           </style>
           <title></title>
           <!--[if (!mso)&(!ie)]>These<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>are<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>for<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>outlook<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>live<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>that<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>removes<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>the first<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
           <!--<![endif]-->
           <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
           <!--<![endif]-->
           <!--[if gte mso 9]>
             <xml>
               <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
                 <o:AllowPNG />
                 <o:PixelsPerInch>96</o:PixelsPerInch>
               </o:OfficeDocumentSettings>
             </xml>
           <![endif]-->
           <style type="text/css">
             .dark-mode .bg-fffffe {
               background-color: #FFFFFE !important;
             }
             .dark-mode .color-4c4e64 {
               color: #4C4E64 !important;
             }
             .dark-mode .color-777777 {
               color: #777777 !important;
             }
             @media (prefers-color-scheme: dark) {
               html:not(.light-mode) .bg-fffffe {
                 background-color: #FFFFFE !important;
               }
               html:not(.light-mode) .color-4c4e64 {
                 color: #4C4E64 !important;
               }
               html:not(.light-mode) .color-777777 {
                 color: #777777 !important;
               }
             }
             [data-ogsc] .bg-fffffe {
               background-color: #FFFFFE !important;
             }
             [data-ogsc] .color-4c4e64 {
               color: #4C4E64 !important;
             }
             [data-ogsc] .color-777777 {
               color: #777777 !important;
             }
           </style>
           <meta name="color-scheme" content="light dark" />
           <meta name="supported-color-schemes" content="light dark" />
           <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
           <!--[if !mso]><!-->
           <meta http-equiv="X-UA-Compatible" content="IE=edge" />
           <!--<![endif]-->
           <meta name="x-apple-disable-message-reformatting" />
           <style></style>
           <meta name="viewport" content="width=device-width, initial-scale=1.0" />
           <style type="text/css">
             u + div .kombai-email-compat__list-with-padding-left {
               padding-left: 0.5em !important;
             }
           </style>
           <!--[if mso]>
             <style type="text/css">
               v\:* {
                 behavior: url(#default#VML);
                 display: inline-block;
               }
               o\:* {
                 behavior: url(#default#VML);
                 display: inline-block;
               }
               w\:* {
                 behavior: url(#default#VML);
                 display: inline-block;
               }
               .ExternalClass {
                 width: 100%;
               }
               table {
                 mso-table-lspace: 0pt;
                 mso-table-rspace: 0pt;
               }
               img {
                 -ms-interpolation-mode: bicubic;
               }
               .ReadMsgBody {
                 width: 100%;
               }
               a {
                 background: transparent !important;
                 background-color: transparent !important;
               }
               li {
                 text-align: -webkit-match-parent;
                 display: list-item;
                 text-indent: -1em;
               }
               ul,
               ol {
                 margin-left: 1em !important;
               }
               p {
                 text-indent: 0;
               }
             </style>
           <![endif]-->
         </head>
         <body style="margin: 0; padding: 0">
           <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
           <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
             <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
               <tr>
                 <td valign="top" width="100.00%" style="padding-top: 32px; padding-bottom: 27.28px; width: 100%; vertical-align: top">
                   <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                     <tr>
                       <td align="center" style="padding-bottom: 13.76px">
                         <img src="assets/image_1fad596c.png" width="101" style="max-width: initial; width: 101px; display: block" />
                       </td>
                     </tr>
                     <tr>
                       <td align="left" style="padding-top: 13.76px; padding-bottom: 11.78px; padding-left: 32px">
                         <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">Admin,</span></p>
                       </td>
                     </tr>
                     <tr>
                       <td align="left" style="padding-top: 11.78px; padding-left: 32px; padding-right: 32px">
                         <div class="color-777777" width="100.00%" style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                           <span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${decodedString.split("|")[0].split("@")[0]}</span><span> has finalized their changes to the contract titled </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${contname} &quot;.</span><span> Please review the proposed changes at your earliest convenience.<br /><br />Please review the request and take the appropriate action to grant or deny edit access.<br /><br />Contract Details:</span>
                           <ul class="kombai-email-compat__list-with-padding-left" style="list-style-type: disc; margin: 0; padding-left: 1.5em">
                             <li><span>Contract Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${contname}</span></li>
                             <li><span>Counterparty Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${decodedString.split("|")[0].split("@")[0]} (${decodedString.split("|")[0]})</span></li>
                             <li><span>Status:</span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left"> Changes Finalised</span></li>
                           </ul>
                         </div>
                       </td>
                     </tr>
                   </table>
                 </td>
               </tr>
             </table>
           </center>
         </body>
       </html>
          `;

        const emailPayload = {
          to: companyStore.AdminEmail,
          body: bodyHtml2,
          subject: "The Document has been Finalized by Counterparty",
        };

        axios
          .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
            headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log("", err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let payload = {
      escrow_id: decodedString.split("|")[2],
      contract_id: decodedString.split("|")[1],
    };
    companyStore.setContractID(decodedString.split("|")[1]);
    companyStore.setEscrowId(decodedString.split("|")[2]);
    // console.log("decodedd",payload);

    axios
      .post(`${config.mock_api_endpoint}/api/getContractData`, payload, {
        // headers: {
        //   // aws_cognito_id_token: authTokens.idToken,
        //   aws_cognito_access_token: authFromUrl?.length > 0 ? authFromUrl : authTokens.accessToken,
        //   "Content-Type": "application/json",
        // },
        headers: {
          "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log("check", res.data);
        setData(res.data);
        setContName(res.data.contract_name);
        let accessNotRemoved = res.data.counter_parties.filter((data) => data.status !== "Removed");
        res.data.counter_parties.map((data) => {
          if (data.email.includes(decodedString.split("|")[0])) {
            setHideDoc(true);
          }
        });
        setCounterparty(accessNotRemoved);
        setHideButton(res.data.status === "Accepted" ? true : false);
        // console.log("counter_party",res.data.counter_parties);
        console.log("emails->>", decodedString.split("|")[0]);
        if (decodedString.split("|")[0] === res.data.current_edit_access) {
          setuserType("edit");
        }
      })
      .catch((err) => {
        console.log("%o", err);
      });
  }, [decodedString, clicked, authFromUrl]);

  function decryptBuffer(encryptedBuffer) {
    const key = CryptoJS.enc.Utf8.parse("1234567890123456"); // Same 128-bit key used in encryption
    const iv = CryptoJS.enc.Utf8.parse("1234567890123456"); // Same IV used in encryption

    // Decrypt the encrypted buffer (base64 string)
    const decrypted = CryptoJS.AES.decrypt(encryptedBuffer, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC, // Cipher Block Chaining mode
      padding: CryptoJS.pad.Pkcs7, // Padding scheme
    });

    // Convert decrypted data to a UTF-8 string
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

    return decryptedData;
  }

  async function handleFileUpload(contract) {
    setLoader(true);
    console.log("Contract", contract);
    window.TXTextControl.addEventListener("textControlLoaded", async function () {
      let payload = {
        escrow_id: decodedString.split("|")[2],
        contract_id: decodedString.split("|")[1],
      };
      try {
        var res = await axios.post(`${config.mock_api_endpoint}/api/getDocument`, payload, {
          headers: {
            "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
            // "Content-Type": "application/json",
          },
        });
        const decryptedData = decryptBuffer(res.data);
        setUrl(decryptedData);
        setLoader(false);
        contract = decryptedData;
      } catch (error) {
        console.log(error);
      }

      contract = contract.replaceAll(
        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
        "",
      );
      window.TXTextControl.loadDocument(window.TXTextControl.StreamType.WordprocessingML, contract);
      window.TXTextControl.setEditMode(window.TXTextControl.EditMode.ReadAndSelect);
      window.TXTextControl.setIsTrackChangesEnabled(true);
    });
  }

  function handleDocumentEditorLoad() {
    console.log("The TXTextControl object exists from now on.");
    handleFileUpload(urldata);
  }

  function SaveAndSendToAssigner() {
    setClickedEdit(true);
    window.TXTextControl.saveDocument(window.TXTextControl.StreamType.WordprocessingML, function (e) {
      var base64Document = e.data;
      //save the base64 string in the database.
      const buffer = Buffer.from(base64Document, "utf8");
      // console.log("from buffer : %o", buffer.toString("utf8"));
      let payload = {
        escrow_id: decodedString.split("|")[2],
        contract_id: decodedString.split("|")[1],
      };
      console.log(payload);

      var formdata = new FormData();
      formdata.append("escrow_id", decodedString.split("|")[2]);
      formdata.append("contract_id", decodedString.split("|")[1]);
      formdata.append("buffer", buffer);
      formdata.append("status", "Changed");
      axios
        .post(`${config.mock_api_endpoint}/api/documentUpdated`, formdata, {
          headers: {
            "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
            // "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("res.data");
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  function MakeDocumentEditable() {
    window.TXTextControl.setEditMode(window.TXTextControl.EditMode.Edit);
    window.TXTextControl.setIsTrackChangesEnabled(true);

    window.TXTextControl.addEventListener("trackedChangeCreated", (e) => {
      const getLength = e.trackedChange.length;
      console.log("created-->", getLength);
      setCount(getLength);
    });

    window.TXTextControl.addEventListener("trackedChangeChanged", (e) => {
      const getLength = e.trackedChange.length;
      console.log("changed-->", getLength);
      setCount(getLength);
    });

    window.TXTextControl.addEventListener("trackedChangeDeleted", (e) => {
      const getLength = e.trackedChange.length;
      console.log("deleted-->", getLength);
      setCount(getLength);
    });
  }

  async function DownloadPDF() {
    window.TXTextControl.saveDocument(window.TXTextControl.StreamType.InternalUnicodeFormat, function (e) {
      var base64Document = e.data;
      //save the base64 string in the database.
      console.log("%o", base64Document);
      window.TXTextControl.saveDocument(window.TXTextControl.StreamType.AdobePDF, function (e) {
        console.log(e.data);
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/pdf;base64," + e.data;
        downloadLink.download = "convertedPDFFile.pdf";
        downloadLink.click();
      });
    });

    // window.TXTextControl.save("AdobePDF",handleSave)
    // // TXTextControl.save(<StreamType> streamType, , [<SaveSettings> saveSettings], [<ErrorCallback> errorCallback])
  }
  // console.log("buttons--->", hideButton, decodedString.split("|")[0], data.current_edit_access, clicked);
  console.log("heyy count:", count);
  return !isSuccess ? (
    <div className={style.container}>
      {hideDoc === true ? (
        <>
          <Spin
            size="large"
            tip="Loading..."
            spinning={loader}
            className="d-flex justify-content-center align-items-center"
            style={{ maxHeight: "none" }}
          >
            {" "}
            <div className={style.btncontainer}>
              {/*3 access for Editor */}
              {!hideButton && decodedString.split("|")[0] == data.current_edit_access && !clicked && (
                <div className={style.btnc}>
                  <button className={style.button} onClick={SaveAndSendToAssigner}>
                    Save & Send
                  </button>

                  <button className={style.button} onClick={MakeDocumentEditable}>
                    Make Changes
                  </button>
                </div>
              )}
              <button className={style.button} onClick={DownloadPDF}>
                Download
              </button>

              {/* 3 access for viewer + download */}
              {!hideButton && decodedString.split("|")[0] !== data.current_edit_access && !clicked && (
                <div className={style.btnc}>
                  <button className={style.button} onClick={sendrequest}>
                    Request Edit Access
                  </button>

                  <button className={style.button} onClick={setFinalize}>
                    Finalise
                  </button>
                </div>
              )}
            </div>
            <DocumentEditor
              style={{ width: "100%", height: "70em" }}
              className={"ribbonGroupTrackedChanges li_tabPermissions"}
              serviceURL="https://trial.dsserver.io"
              // serviceURL="http://192.168.0.95:3005/"
              onLoad={handleDocumentEditorLoad}
              allowAdditionalUserNames={true}
              userNames={[`${decodedString.split("|")[0]}`]}
              //on-premise
              authSettings={{
                clientId: "dsserver.AKIaD0JiLu6kQw87CvpBtxIUp4jpxS06",
                clientSecret: "BDhgBaeFoCK0TuGEYFxu7zV5Z7pVbCpE",
              }}

              //local server
              // authSettings={{
              //   clientId: "dsserver.GfeRmv34X6QIDqbsrSdZSD0XHS4lEUAW",
              //   clientSecret: "DadL7eoET7uaExLDw3DtQBBQrnqAH6nk",
              // }}
            ></DocumentEditor>{" "}
          </Spin>
        </>
      ) : (
        <div className="d-flex justify-content-center w-100 ">
          {"Your access has been removed to view or edit this document."}
        </div>
      )}
      <MakeEditor
        isVisible={clickedEdit}
        setClicked={setClicked}
        onclose={() => {
          setClickedEdit(false);
        }}
        contract_id={contractid}
        escrow_id={Escrowid}
        email={email}
        count={count}
        setIsSuccess={setIsSuccess}
      />
    </div>
  ) : (
    <ContractSuccess />
  );
}

export default EditorView;
