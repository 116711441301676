import PropTypes from "prop-types";
import styles from "./DIrectorApprove.module.css";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
import { useAllProduct } from "../../../../api-context/commonApiContext";
const DIrectorApprove = ({ isVisible, onclose, className = "" }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const kycid = companyStore.Dirct_KYC_id;
  const compid = companyStore.companyID;
  const { authTokens } = useAllProduct();
  const handleApprove = () => {
    let obj = {
      kyc_id: kycid,
      status: "approved",
    };
    axios
      .post(`${config.node_api_endpoint}/v1/director/kyc_request/update_status_demo_fe`, obj, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        console.log("status approved");
        navigate(`/content/company/route`);
        onclose();
      })
      .catch((er) => {
        console.log(er);
        onclose();
      });
  };
  if (!isVisible) return null;
  return (
    <div className={`modal show d-block ${styles.approvalModal}`} tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className={`modal-content ${styles.customModalContent}`}>
          <div className={`modal-body ${styles.customModalBody}`}>
            <p>Are you sure you want to approve?</p>
            <img src="/4019677-2131040-1.svg" alt="Approval Icon" className={styles.icon} />
          </div>
          <div className={`modal-footer d-flex justify-content-center`}>
            <button
              type="button"
              style={{ color: "#2348AB", backgroundColor: "#ffffff", border: "1px solid #2348AB" }}
              className={`btn btn-secondary ${styles.customCancelBtn}`}
              onClick={onclose}
            >
              No
            </button>
            <button
              type="button"
              style={{ backgroundColor: "#2348AB", border: "1px solid #2348AB" }}
              className={`btn btn-primary ${styles.customApproveBtn}`}
              onClick={handleApprove}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DIrectorApprove.propTypes = {
  className: PropTypes.string,
};

export default DIrectorApprove;
