import PropTypes from "prop-types";
import styles from "./RemoveModel.module.css";
import axios from "axios";
import companyStore from "../../../CompanyStore";
import config from "../../../../../service/config";
import { useEffect } from "react";
import { useAllProduct } from "../../../../api-context/commonApiContext";
const RemoveModel = ({ isVisible, party, Counterparty, contName, onclose, partyName, className = "" }) => {
  const formData = new FormData();
  const { authTokens } = useAllProduct();
  const handleRemove = () => {
    let bodyHtml = `
    <html>
    <head>
        <style>
            body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
            .container { max-width: 600px; margin: auto; padding: 20px; }
            h1 { color: #007BFF; }
            a { color: #007BFF; text-decoration: none; }
            .button {
                display: inline-block;
                padding: 10px 20px;
                font-size: 16px;
                color: white;
                background-color: #007BFF;
                border: none;
                border-radius: 5px;
                text-decoration: none;
                text-align: center;
            }
            .footer { margin-top: 20px; font-size: 0.9em; color: #777; }
        </style>
    </head>
    <body style="margin: 0; padding: 0">
    <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
    <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
      <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
        <tr>
          <td valign="top" width="100.00%" style="padding-top: 32px; padding-bottom: 27.16px; width: 100%; vertical-align: top">
            <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
              <tr>
                <td align="center" style="padding-bottom: 13.76px">
                  <img src="assets/image_1fad596c.png" width="101" style="max-width: initial; width: 101px; display: block" />
                </td>
              </tr>
              <tr>
                <td align="left" style="padding-top: 13.76px; padding-bottom: 11.72px; padding-left: 32px">
                  <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${partyName},</span></p>
                </td>
              </tr>
              <tr>
                <td align="left" style="padding-top: 11.72px; padding-left: 32px; padding-right: 32px">
                  <p class="color-777777" width="100.00%" style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                    <span>We regret to inform you that you have been </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">removed</span><span> from the ongoing contract negotiation process for the contract titled </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${companyStore.ContractName} &quot;.</span><span><br />This decision was made by the Contract Admin, and your access to the contract has been revoked effective immediately. If you have any questions or require further clarification, please feel free to reach out to us.<br /><br /></span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">We appreciate your understanding and cooperation.</span>
                  </p>
                </td>
              </tr>
                <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
            </table>
          </td>
        </tr>
      </table>
    </center>
  </body>
    </html>
`;

    let payload1 = {
      escrow_id: companyStore.escrowId,
      contract_id: companyStore.Contract_id,
      counter_parties: [party],
    };

    const emailPayload = {
      to: party,
      body: bodyHtml,
      subject: "Notice of Removal from Contract Negotiation Process",
    };
    // formData.append("subject", "counter party removed");
    console.log("companyStore.ContractName", companyStore.ContractName);

    axios
      .post(`${config.mock_api_endpoint}/api/removeCounterParties`, payload1, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(res.data);
      });
    axios
      .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
      })
      .then((res) => {
        onclose(false);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    Counterparty.forEach((data) => {
      if (data.email !== party) {
        console.log("email: data.email,", data.email, companyStore.ContractName);
        console.log("nameCheck--->", data);

        let bodyHtml = `
        <html>
        <head>
            <style>
                body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
                .container { max-width: 600px; margin: auto; padding: 20px; }
                h1 { color: #007BFF; }
                a { color: #007BFF; text-decoration: none; }
                .button {
                    display: inline-block;
                    padding: 10px 20px;
                    font-size: 16px;
                    color: white;
                    background-color: #007BFF;
                    border: none;
                    border-radius: 5px;
                    text-decoration: none;
                    text-align: center;
                }
                .footer { margin-top: 20px; font-size: 0.9em; color: #777; }
            </style>
        </head>
        <body style="margin: 0; padding: 0">
        <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
        <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
          <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
            <tr>
              <td valign="top" width="100.00%" style="padding-top: 32px; padding-bottom: 27.16px; width: 100%; vertical-align: top">
                <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                  <tr>
                    <td align="center" style="padding-bottom: 13.76px">
                      <img src="assets/image_1fad596c.png" width="101" style="max-width: initial; width: 101px; display: block" />
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="padding-top: 13.76px; padding-bottom: 11.72px; padding-left: 32px">
                      <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${data.name},</span></p>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="padding-top: 11.72px; padding-left: 32px; padding-right: 32px">
                      <p class="color-777777" width="100.00%" style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                        <span>Please be informed that </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${party.split("@")[0]}</span><span> has been removed from the ongoing contract negotiation process for the </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${companyStore.ContractName} &quot;.</span><span><br />All remaining participants can continue with the negotiation as per the assigned roles. If you have any concerns or questions, feel free to contact us.<br /></span><span style="font-size: 20px; font-weight: 700; letter-spacing: 0.4px; text-align: left"><br /></span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">Thank you for your attention and continued participation.</span>
                      </p>
                    </td>
                  </tr>
                  <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
                </table>
              </td>
            </tr>
          </table>
        </center>
      </body>
        </html>
    `;

        const emailPayload = {
          to: data.email,
          body: bodyHtml,
          subject: `Update on Contract Negotiation Participants for - ${companyStore.ContractName}`,
        };

        // formData.append("subject", "counter party removed");
        axios
          .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
            headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
          })
          .then((res) => {
            onclose(false);
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };
  if (!isVisible) return null;

  return (
    <div className={[styles.addCompany, className].join(" ")}>
      <div className={styles.addCompany1}>
        <div className={styles.areYouSure}>Are you sure you want to remove the counterparty?</div>
        <div className={styles.addCompanyInner}>
          <div className={styles.cancelParent}>
            <div
              className={styles.cancel}
              onClick={() => {
                onclose(false);
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown.svg" />
              <b className={styles.cancel1}>No</b>
            </div>
            <div
              className={styles.create}
              onClick={() => {
                handleRemove();
              }}
            >
              <b className={styles.cancel1}>Yes</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RemoveModel.propTypes = {
  className: PropTypes.string,
};

export default RemoveModel;
