import React, { useState } from "react";
import styles from "./NewCompanyModel.module.css";
import axios from "axios";
import config from "../../../../service/config";
import companyStore from "../../CompanyStore";
import { useAllProduct } from "../../../api-context/commonApiContext";

export default function NewCompanyModel({ className = "", isVisible, onclose }) {
  const { authTokens } = useAllProduct();
  const [compName, setCompName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const HandleData = () => {
    const trimmedCompName = compName.trim();

    if (trimmedCompName === "") {
      setErrorMsg("Escrow Name is Required");
      return;
    }
    if (trimmedCompName.length < 5) {
      setErrorMsg("Escrow Name must be at least 5 characters long");
      return;
    }
    setErrorMsg("");
    const payload = { name: trimmedCompName, created_by: companyStore?.AdminEmail };

    axios
      .post(`${config.node_api_endpoint}/v1/escrow`, payload, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        console.log("Company Added");
        setCompName("");
        onclose();
      })
      .catch(() => {
        alert("Could Not Register. Try Again.");
        setCompName("");
        onclose();
      });
  };

  if (!isVisible) {
    return null;
  }
  console.log("msg-->", errorMsg);

  return (
    <div className={styles.newModelcontainer}>
      <div className={[styles.addCompany, className].join(" ")}>
        <div className={styles.newCompany}>New Escrow account</div>
        <div className={styles.mobileMenuParent}>
          <div className={`${styles.mobileMenu} mb-2`}>
            <input
              placeholder="Escrow Name"
              value={compName}
              className={styles.field}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 100) {
                  setErrorMsg("Escrow Name cannot exceed 100 characters");
                } else {
                  setErrorMsg("");
                }
                setCompName(value);
              }}
            />
            <img className={styles.trailingIcon2} alt="" src="/trailingicon-21.svg" />
          </div>
          {errorMsg && <div className={`${styles.errorMsg}`}>{errorMsg}</div>}
          <div className={`${styles.loadsButtonsParent} mt-2`}>
            <div
              className={styles.loadsButtons}
              onClick={() => {
                setCompName("");
                setErrorMsg("");
                onclose();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Cancel</b>
            </div>
            <div
              className={styles.loadsButtons1}
              onClick={() => {
                HandleData();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Create</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
