import PropTypes from "prop-types";
import styles from "./ContractMain.module.css";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import config from "../../../../service/config";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import companyStore from "../../CompanyStore";
import { Pagination } from "antd";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useAllProduct } from "../../../api-context/commonApiContext";
const ContractMain = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [currentcompid, setCurrentCompId] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [currentCompName, setCurrentCompName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { role, authTokens } = useAllProduct();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch users' data
        const userRes = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            aws_cognito_access_token: authTokens.accessToken,
            "Content-Type": "application/json",
          },
        });
        const users = userRes.data.users;

        // Map users by their email for quick lookup
        const userRoleMap = users.reduce((acc, user) => {
          acc[user.email] = user.auth[0].role;
          return acc;
        }, {});

        // Fetch contracts data
        let obj = {
          escrow_id: companyStore.escrowId,
          escrow_name: companyStore.escrowName,
          admin: {
            email: companyStore.AdminEmail,
          },
        };
        console.log("contractAPi", authTokens);

        const contractRes = await axios.post(`${config.mock_api_endpoint}/api/contractList`, obj, {
          headers: {
            // aws_cognito_id_token: authTokens.idToken,
            aws_cognito_access_token: authTokens.accessToken,
            "Content-Type": "application/json",
          },
        });

        // Add roles to the contracts data
        const contractsWithRoles = contractRes?.data?.contracts?.map((contract) => {
          return {
            ...contract,
            role: userRoleMap[contract.created_by] || "Unknown", // Add role or set as "Unknown" if not found
          };
        });

        const sortedData = contractsWithRoles.sort((a, b) => {
          const dateA = dayjs(a.createdAt);
          const dateB = dayjs(b.createdAt);
          return dateB - dateA;
        });
        console.log("user-->", sortedData);
        setData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [showModel, authTokens]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resetFilters = () => {
    setFilteredData(data);
  };

  const applyFilters = () => {
    let filtered = [...data];

    // Check for status filter, if it's not "All" (empty string), apply the status filter
    if (statusFilter && statusFilter !== "") {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        console.log("created_at", item);

        const [datePart] = item.createdAt?.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        console.log("dateFilter--->", itemDate, filterDate);

        return itemDate === filterDate;
      });
    }

    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item?.createdAt?.toLowerCase().includes(value.toLowerCase()) ||
          item?.contract_name?.toLowerCase().includes(value.toLowerCase()) ||
          item?.created_by?.toLowerCase().includes(value.toLowerCase()) ||
          item?.role[0]?.toLowerCase().includes(value.toLowerCase()) ||
          item?.status?.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
    setCurrentPage(0); // Reset to the first page
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * pageSize;
  // const currentPageData = filteredData?.slice(startIndex, startIndex + pageSize);
  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

  return (
    <div className={`${styles.pageContainer} ${styles.family}`}>
      <div
        className={`${styles.newCompany_div} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Maker" ? "" : styles.disabledDiv}`}
      >
        <button
          onClick={() => {
            navigate("/content/contract/newcontract");
          }}
          className={styles.newCompany}
        >
          <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
          <b className={styles.addNewCompany}>Add Contract</b>
        </button>
      </div>

      <div className={`${styles.searchFiltersParent} mb-2 mt-2`}>
        <div className={styles.frameParent}>
          {/* MUI search for table */}
          <div className={styles.leadingIconParent}>
            <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
            <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
          </div>

          <div className={styles.mobileMenuParent}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Created Date"
                value={createdDateFilter}
                onChange={(newValue) => setCreatedDateFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
                sx={{
                  "& .MuiInputBase-root": {
                    width: "250px",
                    borderRadius: "12px",
                  },
                }}
              />
            </LocalizationProvider>

            <FormControl sx={{ width: "250px" }}>
              <InputLabel id="escrow-select-label">Status</InputLabel>
              <Select
                labelId="escrow-select-label"
                id="escrow-select"
                value={statusFilter}
                label="Status"
                onChange={(e) => setStatusFilter(e.target.value)}
                sx={{ borderRadius: "12px" }}
              >
                <MenuItem value="">All</MenuItem> {/* Option for "All" */}
                <MenuItem value={"sent"}>Sent</MenuItem>
                <MenuItem value={"Changed"}>Changed</MenuItem>
                {/* <MenuItem value={"Completed"}>Completed</MenuItem> */}
                <MenuItem value={"Accepted"}>Accepted</MenuItem>
              </Select>
            </FormControl>
            <div className={styles.loadsButtons1} onClick={applyFilters}>
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
              <b className={styles.addNewCompany}>Apply</b>
            </div>
          </div>

          {/* Frame Container ends */}
        </div>
      </div>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead
              className={styles.tableBg}
              sx={{
                "& .MuiTableCell-root": {
                  padding: "16px",
                },
              }}
            >
              <TableRow>
                <TableCell align="center">Created Date</TableCell>
                <TableCell align="center">Contract Name</TableCell>
                <TableCell align="center">Created By</TableCell>
                <TableCell align="center">Role</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.length > 0 ? (
                currentPageData.map((detail) => (
                  <TableRow key={detail.contract_id} sx={{ "& .MuiTableCell-root": { padding: "8px" } }}>
                    <TableCell align="center">{dayjs(detail.createdAt).format("DD MMM YYYY / hh:mm a")}</TableCell>
                    <TableCell align="center">{detail.contract_name}</TableCell>
                    <TableCell align="center">{detail.created_by}</TableCell>
                    <TableCell align="center">{detail.role}</TableCell>
                    <TableCell align="center">{detail.status.toUpperCase()}</TableCell>
                    <TableCell align="center">
                      <Button
                        // className={styles.change}
                        style={{ color: "#2348AB", textDecoration: "underline", textTransform: "lowercase" }}
                        onClick={() => {
                          handleScrollToTop();
                          companyStore.setContractID(detail.contract_id);
                          console.log(detail._id);
                          navigate("/content/contract/contractdetail");
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={`${styles.tableBg}`}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

ContractMain.propTypes = {
  className: PropTypes.string,
};

export default ContractMain;
