import PropTypes from "prop-types";
import styles from "./PageContainerComp.module.css";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import companyStore from "../../CompanyStore";
import AddNewKYC from "../AddKYC/AddNewKYC";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import config from "../../../../service/config";
import { DatePicker } from "@mui/x-date-pickers";
import { Button, Pagination } from "antd";
import dayjs from "dayjs";
import { useAllProduct } from "../../../api-context/commonApiContext";

const PageContainerComp = () => {
  const { role, authTokens } = useAllProduct();
  const [sendRequest, setSendRequest] = useState(false);
  const [directorID, setDirectorID] = useState("");
  const [showModel, setShowModel] = useState(false);
  const [data, setData] = useState([]);
  const [compName, setCompName] = useState("");
  const [escrowName, setEscrowName] = useState("");
  const [check, setCheck] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [textFilter, setTextFilter] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  let [id, setId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  useEffect(() => {
    setId(companyStore.companyID);
    setCompName(companyStore.companyName);
    setEscrowName(companyStore.escrowName);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (authTokens && Object?.keys(authTokens)?.length > 0) {
        try {
          // Fetch KYC list data
          const kycRes = await axios.get(
            `${config.node_api_endpoint}/v1/company/kyc_list?company_id=${companyStore.companyID}`,
            {
              headers: {
                aws_cognito_id_token: authTokens.idToken,
                aws_cognito_access_token: authTokens.accessToken,
                "Content-Type": "application/json",
              },
            },
          );
          const kycData = kycRes.data;

          // Fetch user data
          const userRes = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              aws_cognito_access_token: authTokens.accessToken,
              "Content-Type": "application/json",
            },
          });
          const users = userRes.data.users;

          // Map roles to KYC data
          const dataWithRoles = kycData.map((kycItem) => {
            const user = users.find((u) => u.email === kycItem.created_by);
            return {
              ...kycItem,
              role: user ? user.auth[0].role : "Unknown", // Add role or default to 'Unknown'
            };
          });

          // Sort KYC data by date and update state
          const sortedData = dataWithRoles.sort((a, b) => {
            const dateA = dayjs(a.created_at, "DD MMM YYYY / hh:mm a");
            const dateB = dayjs(b.created_at, "DD MMM YYYY / hh:mm a");
            return dateB - dateA;
          });

          setData(sortedData); // Original data without roles
          setFilteredData(sortedData); // Data enriched with roles and sorted

          // Check specific KYC type and status
          kycData.forEach((detail) => {
            if (
              detail.kyc_type === "Company KYC" &&
              (detail.status === "pending_approval" || detail.status === "approved")
            ) {
              setCheck(true);
            }
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();

    // Set company-related state
    setId(companyStore.companyID);
    setCompName(companyStore.companyName);
    setEscrowName(companyStore.escrowName);
  }, [showModel, authTokens]);

  const handleClick = (para) => {
    if (para[0] === "Company KYC") {
      companyStore.setKYBId(para[1]);
      navigate(`/content/company/companykyc/detail`);
    } else if (para[0] === "Director KYC") {
      companyStore.setDirect_KYC_ID(para[1]);
      navigate(`/content/company/directkyc/detail`);
    }
  };

  const resetFilters = () => {
    setFilteredData(data);
  };

  const applyFilters = () => {
    let filtered = data;

    if (statusFilter) {
      filtered = filtered.filter((item) => item.status === statusFilter);
    }

    // if (createdDateFilter) {
    //   filtered = filtered.filter(
    //     (item) => new Date(item.created_at).toDateString() === new Date(createdDateFilter).toDateString(),
    //   );
    // }
    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        console.log("dateFilter--->", itemDate, filterDate);

        return itemDate === filterDate;
      });
    }

    if (textFilter) {
      filtered = filtered.filter((item) => item.status === textFilter);
    }

    setFilteredData(filtered);
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;
    console.log(data);

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item?.created_at?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.created_by?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.status?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.name?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.kyc_type?.toLowerCase()?.includes("Director KYC"?.toLowerCase()),
      );
    }

    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };
  const handlenavi = () => {
    navigate("/content/company/kyclist/Company");
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * pageSize;
  // const currentPageData = filteredData.slice(startIndex, startIndex + pageSize);
  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
  console.log("currentPageData-->", currentPageData);

  return (
    <Fragment>
      <div className={`${styles.pageContainer} ${styles.family} `}>
        <div
          className={styles.arrowNarrowLeftParent}
          onClick={() => {
            handlenavi();
          }}
        >
          <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft.svg" />
          <div className={styles.back}>Back</div>
        </div>
        <div className={`${styles.frameParent} mt-1`}>
          <b className={`d-flex justify-content-start ${styles.escrowstackPrivateLimited}`}>
            {companyStore?.escrowName}
          </b>
          <div className={`${styles.escrowstackPrivateLimitedParent} mb-2`}>
            <b className={styles.companyNameTitle}>{compName}</b>
            <button
              className={`${styles.loadsButtons} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Maker" ? "" : styles.disabledDiv}`}
              onClick={() => setShowModel(true)}
            >
              <img className={styles.plusCircleIcon} alt="" src="/pluscircle2.svg" />
              <b className={styles.newKycRequest}>New KYC Request</b>
            </button>
          </div>

          <div className={`${styles.frameWrapper} mb-2 p-2`}>
            <div className={styles.frameGroup}>
              {/* Filter inputs */}
              <div className={styles.frameContainer}>
                <div className={styles.frameDiv}>
                  <img className={styles.leadingIcon} alt="" src="/leadingicon3.svg" />
                  <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
                </div>
              </div>

              <div className={styles.mobileMenuParent}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{ "& .MuiInputBase-root": { width: "250px", borderRadius: "12px" } }}
                  />
                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "250px",
                        borderRadius: "12px",
                      },
                    }}
                  />
                </LocalizationProvider>

                <FormControl sx={{ width: "250px" }}>
                  <InputLabel id="escrow-select-label">Status</InputLabel>
                  <Select
                    labelId="escrow-select-label"
                    id="escrow-select"
                    value={statusFilter}
                    label="Status"
                    onChange={(e) => setStatusFilter(e.target.value)}
                    sx={{ borderRadius: "12px" }}
                  >
                    <MenuItem value={"requested"}>Requested</MenuItem>
                    <MenuItem value={"pending_approval"}>Pending Approval</MenuItem>
                    <MenuItem value={"approved"}>Approved</MenuItem>
                    <MenuItem value={"rejected"}>Rejected</MenuItem>
                    <MenuItem value={"SUBMITTED"}>Submitted</MenuItem>
                  </Select>
                </FormControl>

                {/* Button to apply filter */}
                <div className={`${styles.loadsButtons1}`} onClick={applyFilters}>
                  <b className={styles.escrowstackPrivateLimited}>Apply</b>
                </div>
              </div>
            </div>
          </div>
          <div className={`${`${styles.tableBg} d-flex justify-content-between px-2`}`}>
            <h2 className={styles.title}>KYC List</h2>
          </div>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead className={styles.tableBg}>
                  <TableRow>
                    <TableCell align="center">Created Date</TableCell>
                    <TableCell align="center">Name of Company / Authorized Signatory</TableCell>
                    <TableCell align="center">KYC Type</TableCell>
                    <TableCell align="center">Created By</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.length > 0 ? (
                    currentPageData.map((detail) => (
                      <TableRow key={detail._id}>
                        <TableCell align="center">{detail.created_at}</TableCell>
                        <TableCell align="center">{detail.name}</TableCell>
                        <TableCell align="center">
                          {detail.kyc_type === "Director KYC" ? "Signatory KYC" : detail.kyc_type}
                        </TableCell>
                        <TableCell align="center">{detail.created_by}</TableCell>
                        <TableCell align="center">{detail.role}</TableCell>
                        <TableCell align="center">{detail?.status?.toUpperCase()}</TableCell>
                        <TableCell align="center">
                          {detail.kyc_type === "Company KYC" && (
                            <Button
                              className={styles.btn}
                              onClick={() => handleClick([detail.kyc_type, detail.kyb_id])}
                            >
                              <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                            </Button>
                          )}
                          {detail.kyc_type === "Director KYC" && (
                            <Button
                              className={styles.btn}
                              onClick={() => handleClick([detail.kyc_type, detail.kyc_id])}
                            >
                              <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                            </Button>
                          )}
                          {/* {detail.kyc_type === "Director KYC" && detail.status === "requested" && (
                        <button className={styles.btndis}>
                          <img className={styles.eyeIcon} alt="" src="/eye.svg" />
                        </button>
                      )} */}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={7} align="center">
                        No Records Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className={`${styles.tableBg}`}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      <AddNewKYC
        isVisible={showModel}
        onClose={() => {
          setShowModel(false);
        }}
        check={check}
      />
    </Fragment>
  );
};

PageContainerComp.propTypes = {
  className: PropTypes.string,
};

export default PageContainerComp;
