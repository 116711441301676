import React, { useCallback, useEffect, useState } from "react";
import DocumentEditor from "@txtextcontrol/tx-react-ds-document-editor";
import { MenuItem, TextField, FormHelperText, Button, FormControl, Select, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./NewContract.module.css";
import config from "../../../../service/config";
import Lottie from "lottie-react";
import load from "./LoadGIF.json";
import axios from "axios";
import { DateTimePicker, DateTimePickerTabs } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import companyStore from "../../CompanyStore";
import { Buffer } from "buffer";
import { useAllProduct } from "../../../api-context/commonApiContext";

const NewContract = ({ className = "" }) => {
  const { authTokens } = useAllProduct();
  const navigate = useNavigate();
  const [wordDoc, setwordDoc] = useState(null);
  const [adminName, setAdminName] = useState("");
  const [contractAdminName, setContractAdminName] = useState("");
  const [parties, setParties] = useState([
    { name: "", email: "", company_name: "", access: "", status: "Not Finalized" },
  ]);
  const formdata = new FormData();
  const [agreementName, setAgreementName] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [editIndex, setEditIndex] = useState("");
  const [formErrors, setFormErrors] = useState({
    agreementName: "",
    adminName: "",
    parties: [],
    wordDoc: "",
  });
  const onFrameContainerClick = useCallback(() => {
    navigate("/content/contract/main");
  }, [navigate]);

  const handleRoleChange = (index, newRole) => {
    const newParties = [...parties];

    if (newRole === "edit") {
      newParties.forEach((party, i) => {
        if (i !== index && party.access === "edit") {
          party.access = "view";
        }
      });
      setEditIndex(index); // Set the index of the party that has "edit"
    } else if (editIndex === index) {
      setEditIndex(-1); // Reset edit index if the "edit" party is changed to "view"
    }

    newParties[index].access = newRole; // Update the new access
    setParties(newParties);
  };

  const handleAddParty = () => {
    setParties([...parties, { name: "", email: "", company_name: "", access: "Viewer", status: "Not Finalized" }]);
  };

  const handleRemoveParty = (index) => {
    if (parties.length > 1) {
      setParties(parties.filter((_, i) => i !== index));
    }
  };
  const handleClearAll = () => {
    setAgreementName("");
    setExpiryDate(null);
    setParties([{ name: "", email: "", company_name: "", access: "", status: "Not Finalized" }]);
    setAdminName("");
  };

  const handleContinue = () => {
    const errors = {
      agreementName: "",
      adminName: "",
      parties: [],
    };

    const isAgreementNameValid = agreementName.trim() !== "";
    const isAdminNameValid = adminName.trim() !== "";

    if (!isAgreementNameValid) {
      errors.agreementName = "Contract name is required.";
    } else if (agreementName.trim().length < 3) {
      errors.agreementName = "Contract name must be at least 3 characters";
    }
    if (!isAdminNameValid) {
      errors.adminName = "Admin name is required.";
    } else if (!/^[a-zA-Z\s]+$/.test(adminName.trim())) {
      errors.adminName = "Admin name must contain only letters and spaces.";
    } else if (adminName.trim().length < 3) {
      errors.adminName = "Admin name must be at least 3 characters.";
    } else if (adminName.trim().length > 50) {
      errors.adminName = "Admin name must not exceed 50 characters.";
    }

    parties.forEach((party, index) => {
      const partyErrors = [];

      // Check name validation
      if (party.name.trim() === "") {
        partyErrors.push("Name is required.");
      }
      if (party.name.trim()?.length < 3) {
        partyErrors.push("Name should be at least 3 characters.");
      }
      if (!/^[A-Za-z\s]+$/.test(party.name.trim())) {
        partyErrors.push("Name should contain only letters.");
      }

      // Check email validation
      if (party.email.trim() === "") {
        partyErrors.push("Email is required.");
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(party.email)) {
        partyErrors.push("Email is invalid.");
      }

      // Check company name validation
      if (party.company_name.trim() === "") {
        partyErrors.push("Company name is required.");
      }
      if (party.company_name.trim()?.length < 3) {
        partyErrors.push("Company name should be at least 3 characters.");
      }

      if (/[^a-zA-Z\s]/.test(party.company_name.trim())) {
        partyErrors.push("Company name should contain only letters.");
      }

      // Check access level validation
      if (party.access.trim() === "") partyErrors.push("Access level is required.");

      // Update the errors array for the specific party
      if (partyErrors.length > 0) {
        errors.parties[index] = partyErrors;
      } else {
        errors.parties[index] = [];
      }
    });

    setFormErrors(errors);

    // Check if there are any errors
    if (errors.agreementName || errors.adminName || errors.parties.some((partyErrors) => partyErrors.length > 0)) {
      return; // Don't submit the form if there are errors
    }
    const hasEditor = parties.some((party) => party.access.trim().toUpperCase() === "EDIT");

    if (!hasEditor) {
      alert("At least one party must have EDITOR access.");
      return;
    }

    if (!wordDoc || (wordDoc.type == "" && wordDoc.type == "")) {
      alert("Please upload a Word document (.doc or .docx).");
      return;
    }

    const filteredParties = parties.filter((party) => party.name.trim() !== "" && party.email.trim() !== "");

    if (filteredParties?.length < 2) {
      alert("Please add at least two parties to the agreement.");
      return;
    }

    const emailSet = new Set();
    const hasDuplicateEmail = filteredParties.some((party) => {
      if (emailSet.has(party.email)) {
        return true;
      }
      emailSet.add(party.email);
      return false;
    });

    if (hasDuplicateEmail) {
      alert("Each party must have a unique email address.");
      return;
    }
    setClicked(true);
    const chunkSize = 300 * 1024;
    let currentByte = 0;

    // Function to create a chunk of the file buffer
    function getNextChunk(buffer) {
      const chunk = buffer.slice(currentByte, currentByte + chunkSize);
      currentByte += chunkSize;
      return chunk;
    }

    // console.log("-->", wordDoc);
    var newTxt = wordDoc.replaceAll(
      "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
      "",
    );

    const buffer = Buffer.from(newTxt, "utf8");

    const chunks = [];
    while (currentByte < buffer.length) {
      chunks.push(getNextChunk(buffer));
    }
    const blob = new Blob(buffer);
    const url = URL.createObjectURL(blob);
    var reader = new FileReader();
    reader.onloadend = function () {
      var base64data = reader.result;
    };
    var currentuser = "";
    filteredParties.forEach((data) => {
      if (data.access == "edit") {
        currentuser = data.email;
      }
    });

    reader.readAsDataURL(blob);
    const currentDate = new Date().toISOString();
    let status = "sent";
    formdata.append("escrow_name", companyStore.escrowName);
    formdata.append("contract_name", agreementName);
    formdata.append("admin", JSON.stringify({ name: adminName, email: companyStore.AdminEmail }));
    formdata.append("escrow_id", companyStore.escrowId);
    formdata.append("counter_parties", JSON.stringify(filteredParties));
    formdata.append("status", status);
    formdata.append("created_date", currentDate);
    formdata.append("current_edit_access", currentuser);
    // formdata.append("file", blob);
    formdata.append("buffer", buffer);
    //   chunks.forEach((chunk, index) => {
    //     const chunkBlob = (chunk, { type: "text/plain" });
    //     formdata.append(`file_chunk_${index}`, chunkBlob);
    // });
    axios
      .post(`${config.mock_api_endpoint}/api/createContract`, formdata, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("inside create contract");
        companyStore.setContractID(res.data.contract.contract_id);

        res.data.contract.counter_parties.forEach((data1) => {
          console.log("parties--->", data1);
          console.log("data : %o", data1);
          if (data1.access == "view") {
            let bodyHtml = `
            <html>
            <head>
                <style>
                    body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
                    .container { max-width: 600px; margin: auto; padding: 20px; }
                    h1 { color: #007BFF; }
                    a { color: #007BFF; text-decoration: none; }
                    .button {
                        display: inline-block;
                        padding: 10px 20px;
                        font-size: 16px;
                        color: white;
                        background-color: #007BFF;
                        border: none;
                        border-radius: 5px;
                        text-decoration: none;
                        text-align: center;
                    }
                    .footer { margin-top: 20px; font-size: 0.9em; color: #777; }
                </style>
            </head>
          <body style="margin: 0; padding: 0">
        <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
        <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
        <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 32px; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
          <tr>
            <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
              <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
             <tr>
          <td align="center" style="padding-bottom: 13.76px">
            <img src="assets/image_1fad596c.png" width="101" style="max-width: initial; width: 101px; display: block" />
        </td>
        </tr>
                <tr>
                  <td align="left" style="padding-top: 13.76px; padding-bottom: 13.72px; padding-left: 32px">
                    <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${data1.name},</span></p>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="padding-top: 13.72px; padding-bottom: 15.16px; padding-left: 32px; padding-right: 32px">
                    <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%"><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left">You have been added as a </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">viewer</span><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left"> for the contract titled</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${agreementName} &quot;.</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span class="color-777777" style="font-size: 20px; font-weight: 500; color: #777777; letter-spacing: 0.4px; text-align: left">As a viewer, you can access and review the document, track any changes made, and request editing access if needed. </span></p>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="padding-top: 15.16px; padding-bottom: 7.58px; padding-left: 32px">
                    <p width="100.00%" style="font-size: 20px; font-weight: 700; letter-spacing: 0.4px; color: #333333; margin: 0; padding: 0; width: 100%; line-height: 15.68px; mso-line-height-rule: exactly">Please follow the link below to view the contract:</p>
                  </td>
                </tr>
                <tr>
                  <td style="padding-top: 7.58px; padding-left: 33px; padding-right: 33px">
                    <table class="bg-2348ab" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="#2348AB" width="591.00" height="40.00" style="border-radius: 30px; background-color: #2348AB; width: 591px; height: 40px; border-spacing: 0; border-collapse: separate">
                      <tr>
                        <td valign="middle" width="100.00%" height="40.00" style="width: 100%; vertical-align: middle; height: 40px">
          <a href="${config.doc_link_api_endpoint}${data1.doc_link}" style="text-decoration: none;">
            <p class="color-fffffe" width="100.00%" style="font-size: 16px; font-weight: 700; letter-spacing: 0.15px; color: white; margin: 0; padding: 0; width: 100%; line-height: 24px; text-align: center; mso-line-height-alt: 24px">
              View Contract
            </p>
          </a>
                      </tr>
                     
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
           <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
        </table>
        </center>
        </body>
            </html>
        `;

            const emailPayload = {
              to: data1.email,
              body: bodyHtml,
              subject: `View and Track Changes in Contract - ${agreementName}`,
            };

            axios
              .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
                headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
              })
              .then((res) => {
                console.log(res.data);
              })
              .catch((err) => {
                console.log("Mail Server Error", err);
              });
          } else {
            let bodyHtml = `
          <html>
          <head>
              <style>
                  body { font-family: Arial, sans-serif; line-height: 1.6; color: #333; }
                  .container { max-width: 600px; margin: auto; padding: 20px; }
                  h1 { color: #007BFF; }
                  a { color: #007BFF; text-decoration: none; }
                  .button {
                      display: inline-block;
                      padding: 10px 20px;
                      font-size: 16px;
                      color: white;
                      background-color: #007BFF;
                      border: none;
                      border-radius: 5px;
                      text-decoration: none;
                      text-align: center;
                  }
                  .footer { margin-top: 20px; font-size: 0.9em; color: #777; }
              </style>
          </head>
        <body style="margin: 0; padding: 0">
    <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
    <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
      <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 32px; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
        <tr>
          <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
            <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
              <tr>
                <td align="center" style="padding-bottom: 13.76px">
                  <img src="assets/image_1885faaf.png" width="101" style="max-width: initial; width: 101px; display: block" />
                </td>
              </tr>
              <tr>
                <td align="left" style="padding-top: 13.76px; padding-bottom: 13.72px; padding-left: 32px">
                  <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${data1.name},</span></p>
                </td>
              </tr>
              <tr>
                <td align="left" style="padding-top: 13.72px; padding-bottom: 15.16px; padding-left: 32px; padding-right: 32px">
                  <p width="100.00%" style="text-align: left; color: #777777; margin: 0; padding: 0; width: 100%"><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left">You have been assigned as an </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">Editor</span><span class="color-777777" style="font-size: 20px; font-weight: 400; color: #777777; letter-spacing: 0.4px; text-align: left"> for the contract titled</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span style="font-size: 20px; font-weight: 600; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${agreementName} &quot;.</span><span class="color-777777" style="font-size: 20px; font-weight: 600; color: #777777; letter-spacing: 0.4px; text-align: left"> </span><span class="color-777777" style="font-size: 20px; font-weight: 500; color: #777777; letter-spacing: 0.4px; text-align: left"> As an editor, you can review and make changes to the document directly through our document editor. </span></p>
                </td>
              </tr>
              <tr>
                <td align="left" style="padding-top: 15.16px; padding-bottom: 7.58px; padding-left: 32px">
                  <p width="100.00%" style="font-size: 20px; font-weight: 700; letter-spacing: 0.4px; color: #333333; margin: 0; padding: 0; width: 100%; line-height: 15.68px; mso-line-height-rule: exactly">Please follow the link below to edit the contract:</p>
                </td>
              </tr>
              <tr>
                <td style="padding-top: 7.58px; padding-left: 33px; padding-right: 33px">
                  <table class="bg-2348ab" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="#2348AB" width="591.00" height="40.00" style="border-radius: 30px; background-color: #2348AB; width: 591px; height: 40px; border-spacing: 0; border-collapse: separate">
                    <tr>
                      <td valign="middle" width="100.00%" height="40.00" style="width: 100%; vertical-align: middle; height: 40px">
  <a href="${config.doc_link_api_endpoint}${data1.doc_link}" style="text-decoration: none;">
    <p class="color-fffffe" width="100.00%" style="font-size: 16px; font-weight: 700; letter-spacing: 0.15px; color: white; margin: 0; padding: 0; width: 100%; line-height: 24px; text-align: center; mso-line-height-alt: 24px">
      Edit Contract
    </p>
  </a>
</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
            </table>
          </td>
        </tr>
      </table>
    </center>
  </body>
          </html>
      `;

            const emailPayload = {
              to: data1.email,
              body: bodyHtml,
              subject: `Access and Edit Contract - ${agreementName}`,
            };

            axios
              .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
                headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
              })
              .then((res) => {
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
        navigate(`/content/contract/contractdetail`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function uploadFile(event) {
    let file = event.target.files[0];

    if (file) {
      var fileReader = new FileReader();
      fileReader.onload = (e) => {
        var contract = e.target.result;

        setwordDoc(contract);
      };
      fileReader.readAsDataURL(file);
    }
  }

  console.log("formErrors", formErrors);

  return (
    <div className={`${styles.frameParent}`}>
      {/* <div className={styles.framehidden}></div> */}
      <div className={styles.frameWrapper} onClick={onFrameContainerClick}>
        <div className={styles.arrowNarrowLeftParent}>
          <img className={styles.arrowNarrowLeftIcon} alt="" src="/arrownarrowleft.svg" />
          <div className={styles.back}>Back</div>
        </div>
      </div>
      <div></div>
      <div className={`${styles.frameGroup} w-100`}>
        <div className={styles.frameContainer}>
          <div className={styles.uploadTheFinalDraftOfAgreParent}>
            <div className={styles.uploadTheFinal}>+ Upload the agreement</div>
            <div className={styles.uploadTheFinal}>Upload only word Document</div>
          </div>
          <img className={styles.imagePlaceholderIcon} alt="" src="/image-placeholder.svg" />
          <button className={styles.groupParent}>
            <img className={styles.frameChild} alt="" src="/group-6.svg" />
            <div className={styles.dragDropContainer}>
              <span className={styles.dragDrop}>{`Drag & drop the file here or   `}</span>
              <input
                type="file"
                className={styles.fileInput}
                accept=".doc,.docx"
                onChange={(e) => {
                  uploadFile(e);
                }}
              />
            </div>
          </button>
        </div>
        <div className={styles.frameDiv}>
          <div className={styles.partiesParent}>
            <div className={styles.parties}>Enter Contract details</div>
            <div className={styles.input} onClick={handleClearAll}>
              Clear all
            </div>
          </div>
          <ol>
            <div className={styles.frameParent1}>
              <div className={styles.mobileMenuParent}>
                <TextField
                  className={styles.mobileMenu}
                  color="primary"
                  label="Name of Contract"
                  variant="outlined"
                  value={agreementName}
                  onChange={(e) => setAgreementName(e.target.value)}
                  sx={{
                    "& .MuiInputBase-root": { height: "56px", borderRadius: "12px" },
                    width: "309px",
                  }}
                  error={!!formErrors.agreementName}
                  helperText={formErrors.agreementName}
                />
                <TextField
                  className={styles.mobileMenu}
                  color="primary"
                  label="Contract Admin's name"
                  variant="outlined"
                  value={adminName}
                  onChange={(e) => setAdminName(e.target.value)}
                  sx={{
                    "& .MuiInputBase-root": { height: "56px", borderRadius: "12px" },
                    width: "309px",
                  }}
                  error={!!formErrors.adminName}
                  helperText={formErrors.adminName}
                />
              </div>
            </div>
            {parties.map((party, index) => (
              <div key={index} className={styles.frameWrapper1}>
                <div className={styles.frame1}>
                  <li className={styles.partieslistitem}>Counter Party</li>
                  {index >= 1 && (
                    <Button className={styles.inputParent} onClick={() => handleRemoveParty(index)}>
                      <div className={styles.input1}>Remove</div>
                      <img className={styles.arrowNarrowLeftIcon} alt="" src="/minuscircle.svg" />
                    </Button>
                  )}
                </div>
                <div className={styles.mobileMenuParent1}>
                  <div className={styles.mobileMenuParent}>
                    <div className={styles.mobileMenu2}>
                      <TextField
                        className={styles.field}
                        color="primary"
                        label="Name"
                        variant="outlined"
                        value={party.name}
                        onChange={(e) => {
                          const newParties = [...parties];
                          newParties[index].name = e.target.value;
                          setParties(newParties);
                        }}
                        sx={{
                          "& .MuiInputBase-root": { height: "56px", borderRadius: "12px" },
                          width: "309px",
                        }}
                        error={
                          !!formErrors.parties[index]?.find(
                            (e) =>
                              e === "Name is required." ||
                              e === "Name should be at least 3 characters." ||
                              e === "Name should contain only letters.",
                          )
                        }
                        helperText={
                          formErrors.parties[index]?.find((e) => e === "Name is required.") ||
                          formErrors.parties[index]?.find((e) => e === "Name should contain only letters.") ||
                          formErrors.parties[index]?.find((e) => e === "Name should be at least 3 characters.") ||
                          ""
                        }
                      />
                      <img className={styles.trailingIcon2} alt="" src="/trailingicon-2.svg" />
                    </div>
                    <TextField
                      className={styles.mobileMenu3}
                      color="primary"
                      label="Company Name"
                      variant="outlined"
                      value={party.company_name}
                      onChange={(e) => {
                        const newParties = [...parties];
                        newParties[index].company_name = e.target.value;
                        setParties(newParties);
                      }}
                      sx={{
                        "& .MuiInputBase-root": { height: "56px", borderRadius: "12px" },
                        width: "309px",
                      }}
                      error={
                        !!formErrors.parties[index]?.find(
                          (e) =>
                            e === "Company name is required." ||
                            e === "Company name should be at least 3 characters." ||
                            e === "Company name should contain only letters.",
                        )
                      }
                      helperText={formErrors.parties[index]?.find(
                        (e) =>
                          e === "Company name is required." ||
                          e === "Company name should be at least 3 characters." ||
                          e === "Company name should contain only letters.",
                        "",
                      )}
                    />
                  </div>
                  <div className={styles.mobileMenuParent}>
                    <TextField
                      className={styles.mobileMenu3}
                      color="primary"
                      label="Email ID"
                      variant="outlined"
                      type="email"
                      value={party.email}
                      onChange={(e) => {
                        const newParties = [...parties];
                        newParties[index].email = e.target.value;
                        setParties(newParties);
                      }}
                      sx={{
                        "& .MuiInputBase-root": { height: "56px", borderRadius: "12px" },
                        width: "309px",
                      }}
                      error={
                        !!formErrors.parties[index]?.find(
                          (e) => e === "Email is required." || e === "Email is invalid.",
                        )
                      }
                      helperText={
                        formErrors.parties[index]?.find((e) => e === "Email is required.") ||
                        formErrors.parties[index]?.find((e) => e === "Email is invalid.") ||
                        ""
                      }
                    />
                    <FormControl
                      className={styles.editorParent}
                      variant="outlined"
                      error={!!formErrors.parties[index]?.find((e) => e === "Access level is required.")}
                    >
                      <InputLabel color="primary">Access</InputLabel>
                      <Select
                        labelId={`access-select-label-${index}`}
                        id={`access-select-${index}`}
                        value={party.access || ""} // Ensure a valid value is always present
                        label="Access"
                        onChange={(e) => handleRoleChange(index, e.target.value)} // Make sure this handler is updating state properly
                        sx={{ borderRadius: "12px", width: "309px" }}
                      >
                        <MenuItem value="view">VIEWER</MenuItem>
                        <MenuItem value="edit">EDITOR</MenuItem>
                      </Select>
                      <FormHelperText>
                        {formErrors.parties[index]?.find((e) => e === "Access level is required.") || ""}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
              </div>
            ))}
          </ol>

          <div className={styles.containbuttons}>
            <Button className={styles.addDirector} onClick={handleAddParty}>
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
              <img className={styles.arrowNarrowLeftIcon} alt="" src="/pluscircle1.svg" />
              <b className={styles.addDirector1}>Add Parties</b>
            </Button>
            <div
              className={styles.generateRequest}
              onClick={() => {
                handleContinue();
              }}
            >
              {clicked && <Lottie className={styles.httpslottiefilescomanimatIcon} animationData={load} loop={true} />}
              <b className={styles.generateRequest1}>Continue</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NewContract.propTypes = {
  className: PropTypes.string,
};

export default NewContract;
