import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import styles from "./app.module.css";
import Login from "./components/login/Login";
import Content from "./components/Content/Content";
import PageContainer from "./components/Content/companies/EscrowContainer";
import KYCList from "./components/Content/KYCList/KYCList";
import EsignList from "./components/Content/Esign/EsignList";
import companyStore from "./components/Content/CompanyStore";
import ContractList from "./components/Content/contract/ContracList";
import EditorView from "./components/Content/contract/EditorView/EditorView";
import UserManagement from "./components/user-management/UserManagement";
function App() {
  return (
    <div className={styles.containAll}>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route path="/content" element={<Content />}>
          <Route index element={<PageContainer />} />
          <Route path="company/*" element={<KYCList />} />
          <Route path="esign/*" element={<EsignList />} />
          <Route path="contract/*" element={<ContractList />} />
        </Route>
        <Route path="/user-management" element={<Content />}>
          <Route index element={<UserManagement />} />
        </Route>
        <Route path="editor-contract" element={<EditorView />} />
      </Routes>
    </div>
  );
}

export default App;
