import PropTypes from "prop-types";
import styles from "./CompanyRejected.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../../../../service/config";
import { useState } from "react";
import companyStore from "../../../CompanyStore";
import { useAllProduct } from "../../../../api-context/commonApiContext";

const CompanyRejected = ({ isVisible, onclose, className = "" }) => {
  const { authTokens } = useAllProduct();
  const compID = companyStore.companyID;
  const navigate = useNavigate();

  const [reason, setReason] = useState("");

  const onReject = () => {
    if (!reason.trim()) {
      alert("Reason for rejection is required!");
      return;
    }

    let obj = {
      kyb_id: companyStore.KYBId,
      status: "rejected",
      reason: reason,
    };
    axios
      .post(`${config.node_api_endpoint}/v1/company/kyb/update_status`, obj, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        console.log("Request has been rejected");
        navigate(`/content/company/reject`);
      })
      .catch((err) => {
        console.log(err);
      });
    onclose();
  };

  if (!isVisible) return null;

  return (
    <div className={`modal show d-block ${styles.rejectionModal}`} tabIndex="-1" role="dialog">
      <div className={`modal-dialog modal-dialog-centered ${styles.modalDialog}`} role="document">
        <div className={`modal-content ${styles.modalContent}`}>
          <div className={`modal-header ${styles.modalHeader}`}>
            <h5 className={`modal-title ${styles.modalTitle}`}>Please provide Reason for Rejection</h5>
            <button
              type="button"
              className={`btn-close ${styles.closeButton}`}
              aria-label="Close"
              onClick={onclose}
            ></button>
          </div>
          <div className={`modal-body ${styles.modalBody}`}>
            <textarea
              className={`form-control ${styles.textarea}`}
              placeholder="Write reason for reject..."
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className={`modal-footer ${styles.modalFooter}`}>
            <div
              className={`${styles.cancelButton}  d-flex justify-content-center align-items-center`}
              onClick={onclose}
            >
              <button type="button" className={`btn`} style={{ color: "#2348AB" }}>
                Cancel
              </button>
            </div>
            <div
              className={`${styles.rejectButton}  d-flex justify-content-center align-items-center`}
              onClick={onReject}
            >
              <button type="button" className={`btn`} style={{ color: "#ffffff" }}>
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyRejected.propTypes = {
  isVisible: PropTypes.bool,
  onclose: PropTypes.func,
  className: PropTypes.string,
};

export default CompanyRejected;
