import { FormControl, TextField, CircularProgress, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { IconButton, LinearProgress, Typography, Box, Button, Paper } from "@mui/material";
import { CloudUpload, Close, Check } from "@mui/icons-material";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
import { useNavigate } from "react-router-dom";
import styles from "../../CompanyKYC/CompanyKYC.module.css";
import { Spin } from "antd";
import { useAllProduct } from "../../../../api-context/commonApiContext";

const OfflineKyc = ({ setLoader, loader }) => {
  const [cin, setCin] = useState("");
  const [pan, setPan] = useState("");
  const [gst, setGst] = useState("");
  const [remark, setRemark] = useState("");
  const [error, setError] = useState("");
  const [files, setFiles] = useState([]);
  const [escrow_id, setEscrow_Id] = useState([]);
  const [company_id, setCompany_Id] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState({});
  const { authTokens } = useAllProduct();

  useEffect(() => {
    setEscrow_Id(companyStore.escrowId);
    setCompany_Id(companyStore.companyID);
    setAdmin(companyStore.AdminEmail);
  }, []);

  useEffect(() => {
    return () => files?.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const validateField = (field, value) => {
    let errorMessage = "";

    switch (field) {
      case "cin":
        if (!value.trim()) errorMessage = "CIN is required.";
        else if (!/^[A-Za-z0-9]{21}$/i.test(value.trim())) errorMessage = "CIN must be 21 alphanumeric characters.";
        break;

      case "pan":
        if (!value.trim()) errorMessage = "PAN is required.";
        else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(value.trim()))
          errorMessage = "PAN must be in a valid format (ABCDE1234F).";
        break;

      case "gst":
        if (!value.trim()) errorMessage = "GSTIN is required.";
        else if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{3}$/i.test(value.trim()))
          errorMessage = "GSTIN must be in a valid format.";
        break;

      case "remark":
        if (value.trim().length > 250) errorMessage = "Remark cannot exceed 250 characters.";
        break;

      default:
        break;
    }

    return errorMessage;
  };

  const validateForm = () => {
    const errors = {
      cin: validateField("cin", cin),
      pan: validateField("pan", pan),
      gst: validateField("gst", gst),
      remark: validateField("remark", remark),
    };

    setFieldErrors(errors);

    return !Object.values(errors).some((error) => error);
  };

  const handleClear = () => {
    setCin("");
    setPan("");
    setGst("");
    setRemark("");
    setFiles([]);
    setError("");
    setFieldErrors("");
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    if (files.length === 0) {
      alert("Please upload document");
      return;
    }

    setLoader(true); // Start loader before processing begins
    try {
      const formData = new FormData();
      formData.append("escrow_id", escrow_id);
      formData.append("company_id", company_id);
      formData.append("submitted_by", admin);
      formData.append("cin", cin);
      formData.append("pan", pan);
      formData.append("gst", gst);
      formData.append("mode", "offline");
      formData.append("remark", remark);

      files.forEach((file) => {
        formData.append("files", file.file);
      });

      await axios.post(`${config.node_api_endpoint}/v2/company/manualkyc`, formData, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "multipart/form-data",
        },
      });
      setError("Submission successful!");
      navigate(`/content/company/kycsuccess`);
    } catch (error) {
      setError("An error occurred while submitting. Please try again.");
    } finally {
      setLoader(false); // Stop loader after processing completes
      setSnackbarOpen(true);
    }
  };

  const handleFileChange = (event) => {
    const validExtensions = [".pdf", ".jpeg", ".jpg"];
    const newFiles = Array.from(event.target.files).map((file) => ({
      file,
      name: file.name,
      size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
      progress: 0,
      preview: URL.createObjectURL(file),
    }));

    const existingFileNames = files.map((f) => f.name);
    const existingFileSizes = files.map((f) => f.file.size);

    // Filter out files that already exist
    const uniqueFiles = newFiles.filter(
      (newFile) => !existingFileNames.includes(newFile.name) || !existingFileSizes.includes(newFile.file.size),
    );

    if (uniqueFiles.length === 0) {
      // setError("File(s) already uploaded.");
      // setSnackbarOpen(true);
      alert("Duplicate files are not allowed.");
      return;
    }

    const isValidFile = (file) => {
      const extension = file.name.toLowerCase().slice(file.name.lastIndexOf("."));
      return validExtensions.includes(extension);
    };

    const filteredFiles = uniqueFiles.filter(({ file }) => isValidFile(file));

    if (filteredFiles.length !== uniqueFiles.length) {
      // setError("Only PDF or JPEG files are allowed.");
      // setSnackbarOpen(true);
      alert("Only PDF or JPEG files are allowed.");
      return;
    }

    const totalSize = uniqueFiles.reduce((acc, file) => acc + file.file.size, 0) / (1024 * 1024);

    if (files.length + uniqueFiles.length > 10 || totalSize > 5) {
      // setError("Max 10 files and total file size 5MB");
      // setSnackbarOpen(true);
      alert("Max 10 files and total file size 5MB");
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
    simulateUpload(uniqueFiles);
  };

  const simulateUpload = (newFiles) => {
    newFiles.forEach((file) => {
      const interval = setInterval(() => {
        setFiles((prevFiles) => {
          const updatedFiles = prevFiles.map((f) => {
            if (f.name === file.name) {
              const newProgress = Math.min(f.progress + 10, 100);
              if (newProgress === 100) {
                clearInterval(interval);
              }
              return { ...f, progress: newProgress };
            }
            return f;
          });
          return updatedFiles;
        });
      }, 200);
    });
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleVerify = async () => {
    if (!validateForm()) return;

    setLoader(true); // Start loader before processing begins
    try {
      const formData = new FormData();
      formData.append("company_id", company_id);
      formData.append("created_by", companyStore?.AdminEmail);
      formData.append("cin", cin);
      formData.append("pan", pan);
      formData.append("gst", gst);
      formData.append("mode", "offline");
      formData.append("submitted_by", admin);
      formData.append("remark", remark);
      files.forEach((file) => {
        formData.append("files", file.file);
      });

      const res1 = await axios.post(`${config.webhook_api_endpoint}/v2/company/verifykyc`, formData, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("res111-->>", res1);
      setError("Submission successful!");
      navigate(`/content/company/kycsuccess`);
    } catch (err) {
      setError("Error occurred while submitting the form");
    } finally {
      setLoader(false); // Stop loader after processing completes
      setSnackbarOpen(true);
    }
  };

  return (
    <div>
      <div className={`${styles.companyKycParent} mb-5`}>
        <div className={styles.companyKyc}>Company KYC</div>
        <button className={styles.input} onClick={handleClear}>
          Clear
        </button>
      </div>
      <div className="w-100 d-flex justify-content-between mb-4 gap-3">
        <FormControl className="w-50">
          <TextField
            label="CIN"
            value={cin}
            onChange={(e) => setCin(e.target.value)}
            variant="outlined"
            onBlur={() => setFieldErrors((prev) => ({ ...prev, cin: validateField("cin", cin) }))}
            error={!!fieldErrors.cin}
            helperText={fieldErrors.cin}
          />
        </FormControl>
        <FormControl className="w-50">
          <TextField
            label="PAN"
            value={pan}
            onChange={(e) => setPan(e.target.value)}
            variant="outlined"
            onBlur={() => setFieldErrors((prev) => ({ ...prev, pan: validateField("pan", pan) }))}
            error={!!fieldErrors.pan}
            helperText={fieldErrors.pan}
          />
        </FormControl>
        <FormControl className="w-50">
          <TextField
            label="GSTIN"
            value={gst}
            onChange={(e) => setGst(e.target.value)}
            variant="outlined"
            onBlur={() => setFieldErrors((prev) => ({ ...prev, gst: validateField("gst", gst) }))}
            error={!!fieldErrors.gst}
            helperText={fieldErrors.gst}
          />
        </FormControl>
      </div>
      <div className="w-100 d-flex justify-content-around mb-4">
        <FormControl className="w-100">
          <TextField
            label="Remark"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            variant="outlined"
            onBlur={() => setFieldErrors((prev) => ({ ...prev, remark: validateField("remark", remark) }))}
            error={!!fieldErrors.remark}
            helperText={fieldErrors.remark}
            InputProps={{ style: { height: 80 } }} // Adjust height here
          />
        </FormControl>
      </div>
      <Typography variant="h6" gutterBottom>
        Upload Documents
      </Typography>
      <div className="d-flex justify-content-start">
        <Box
          sx={{
            width: "50%",
            // maxWidth: 400,
            // margin: "auto",
            padding: 2,
          }}
        >
          {files?.map((file, index) => (
            <Paper
              key={index}
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                padding: 1,
                marginBottom: 1,
                borderRadius: 2,
                border: "1px solid #e0e0e0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 2,
                }}
              >
                {file &&
                  file.preview &&
                  (file.file.type === "application/pdf" ? (
                    <embed
                      src="/pdf-logo.svg"
                      alt={file.name}
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: 4,
                      }}
                    />
                  ) : (
                    <img
                      src={file.preview}
                      alt={file.name}
                      style={{
                        width: 40,
                        height: 40,
                        objectFit: "cover",
                        borderRadius: 4,
                      }}
                    />
                  ))}
              </Box>

              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 1,
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {file.name}
                </Typography>
                <Typography variant="caption" color="textSecondary" className="w-100">
                  {file.size}
                </Typography>
              </Box>
              {/* <Box sx={{ flex: 2, marginRight: 1 }}>
                  {file.progress < 100 && (
                    loader && <LinearProgress variant="determinate" value={file.progress} sx={{ height: 8, borderRadius: 5 }} />
                  )}
                </Box> */}
              <IconButton onClick={() => handleRemoveFile(index)} size="small">
                <Close fontSize="small" />
              </IconButton>
            </Paper>
          ))}
          <Button
            variant="contained"
            component="label"
            // startIcon={<CloudUpload />}
            startIcon={
              <img
                src="/fileUpload.svg"
                alt="Upload Icon"
                style={{
                  width: 24, // Adjust the width to your preference
                  height: 24, // Adjust the height to your preference
                }}
              />
            }
            sx={{
              marginTop: 2,
              padding: 1.5,
              border: "2px dotted #AAAAAA",
              borderRadius: 2,
              fontSize: "14px",
              width: "100%",
              backgroundColor: "#ffffff",
              color: "#777777",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          >
            Drag & drop the file here or &nbsp;{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "#2348AB",
                fontWeight: 700,
              }}
            >
              {" "}
              Click to upload
            </span>
            <input type="file" hidden multiple accept="application/pdf,image/jpeg" onChange={handleFileChange} />
          </Button>
          <Typography variant="caption" display="block" sx={{ marginTop: 1, textAlign: "center", color: "#757575" }}>
            (File type PDF & JPEG / Max 10 / Max Total File Size 5MB)
          </Typography>
        </Box>
      </div>
      <div className="d-flex justify-content-start align-items-center w-50 p-4 gap-3">
        <button
          type="button"
          style={{ backgroundColor: "#FFFFFF", color: "#2348AB" }}
          className="btn btn-primary mt-3 mb-2 w-25 "
          onClick={handleVerify}
        >
          {"Verify"}
        </button>
        <button
          type="button"
          style={{ backgroundColor: "#2348AB", color: "#FFFFFF" }}
          className="btn btn-primary mt-3 mb-2 w-25"
          onClick={handleSubmit}
        >
          {"Submit"}
        </button>
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message={error} />
    </div>
  );
};
export default OfflineKyc;
