import React, { useState } from "react";
import styles from "./NewCompanyModel.module.css";
import axios from "axios";
import config from "../../../../../service/config";
import companyStore from "../../../CompanyStore";
import { useAllProduct } from "../../../../api-context/commonApiContext";

export default function NewCompanyModel({ className = "", isVisible, onclose, setShowModel }) {
  const [compName, setCompName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { authTokens } = useAllProduct();

  const HandleData = () => {
    if (compName.trim() === "") {
      setErrorMsg("Company Name is Required");
      return;
    }
    if (compName.trim().length < 5) {
      setErrorMsg("Company Name must be at least 5 characters long");
      return;
    }

    const payload = {
      escrow_id: companyStore.escrowId,
      created_by: companyStore?.AdminEmail,
      company_name: compName,
    };

    axios
      .post(`${config.node_api_endpoint}/v2/company/create`, payload, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        console.log("Company Added");
        setCompName("");
        onclose();
      })
      .catch(() => {
        alert("Could Not Register Try Again ");
        setCompName("");
        onclose();
      });
  };
  if (!isVisible) {
    return null;
  }
  return (
    <div className={styles.newModelcontainer}>
      <div className={[styles.addCompany, className].join(" ")}>
        {/* <div className={styles.newCompany}>Create New Company</div> */}
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <span style={{ fontWeight: "500" }}>Create New Company</span>
          <img
            className={styles.xCircleIcon}
            alt=""
            src="/xcircle.svg"
            onClick={() => {
              // onClose();
              setShowModel(false);
              setCompName("");
              setErrorMsg("");
            }}
          />
        </div>

        <div className={styles.mobileMenuParent}>
          <div className={`${styles.mobileMenu} mb-2`}>
            <input
              placeholder="Company Name"
              value={compName}
              className={styles.field}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length > 100) {
                  setErrorMsg("Comapny Name cannot exceed 100 characters");
                } else {
                  setErrorMsg("");
                }
                setCompName(value);
              }}
            />

            <img className={styles.trailingIcon2} alt="" src="/trailingicon-21.svg" />
          </div>
          {errorMsg && <div className={`${styles.errorMsg}`}>{errorMsg}</div>}
          <div className={`${styles.loadsButtonsParent} mt-2`}>
            <div
              className={styles.loadsButtons}
              onClick={() => {
                setCompName("");
                setErrorMsg("");
                onclose();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Cancel</b>
            </div>
            <div
              className={styles.loadsButtons1}
              onClick={() => {
                HandleData();
              }}
            >
              <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
              <b className={styles.loads}>Create</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
