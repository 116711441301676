import DocumentEditor from "@txtextcontrol/tx-react-ds-document-editor";
import axios from "axios";
import config from "../../../../service/config";
import { useEffect, useState } from "react";
import companyStore from "../../CompanyStore";
import style from "./ContractView.module.css";
import FileInput from "./FileInput.js";
import MakeEditor from "./makeEditor/MakeEditor.js";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import "../AdminView/DocumentEditor.css";
import { Spin } from "antd";
import { useAllProduct } from "../../../api-context/commonApiContext.js";
const CryptoJS = require("crypto-js");
function Contractview() {
  const [clicked, setClicked] = useState(false);
  const [pdfbase64, setpdfbase64] = useState("");
  const [Completed, setcomplete] = useState(true);
  const [acceptAllTriggered, setAcceptAllTriggered] = useState(false);
  const [contname, setContName] = useState("");
  const [hide, setHide] = useState(false);
  const navigate = useNavigate();
  const [counter_parties, setcounter_parties] = useState([]);
  const [loader, setLoader] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const [makeEditor, setMakeEditor] = useState(false);
  const { role, authTokens } = useAllProduct();
  var urldata = "";
  var date = new Date().toISOString();

  function decryptBuffer(encryptedBuffer) {
    const key = CryptoJS.enc.Utf8.parse("1234567890123456"); // Same 128-bit key used in encryption
    const iv = CryptoJS.enc.Utf8.parse("1234567890123456"); // Same IV used in encryption

    // Decrypt the encrypted buffer (base64 string)
    const decrypted = CryptoJS.AES.decrypt(encryptedBuffer, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC, // Cipher Block Chaining mode
      padding: CryptoJS.pad.Pkcs7, // Padding scheme
    });

    // Convert decrypted data to a UTF-8 string
    const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);

    return decryptedData;
  }

  async function handleFileUpload(contract) {
    setLoader(true);
    console.log("Contract", contract);
    window.TXTextControl.addEventListener("textControlLoaded", async function () {
      let obj = {
        escrow_id: companyStore.escrowId,
        contract_id: companyStore.Contract_id,
      };
      try {
        var res = await axios.post(`${config.mock_api_endpoint}/api/getDocument`, obj, {
          headers: {
            "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
            // "Content-Type": "application/json",
          },
        });
        console.log("res.date : %o", res);
        const decryptedData = decryptBuffer(res.data);

        setLoader(false);
        setpdfbase64(decryptedData);
        contract = decryptedData;
      } catch (error) {
        console.log(error);
      }

      contract = contract.replaceAll(
        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
        "",
      );
      // window.TXTextControl.ribbonPermissionsTab.allowAdditionalUserNames=true;
      // window.TXTextControl.registeredUserNames=['Vibhav@escrowstack.io']
      window.TXTextControl.loadDocument(window.TXTextControl.StreamType.WordprocessingML, contract);
      // } else if (contract.indexOf("data:application/pdf;base64,")!==-1) {
      //   contract = contract.replaceAll("data:application/pdf;base64,","");
      //   window.TXTextControl.loadDocument(window.TXTextControl.StreamType.AdobePDF, contract);
      // } else {
      //   window.TXTextControl.loadDocument(window.TXTextControl.StreamType.InternalUnicodeFormat, contract);
      // }

      window.TXTextControl.setEditMode(window.TXTextControl.EditMode.ReadAndSelect);
      window.TXTextControl.setIsTrackChangesEnabled(true);
    });
    // ?    if(contract.indexOf("wordprocessingml")!=-1){
  }
  function handleDocumentEditorLoad() {
    console.log("The TXTextControl object exists from now on.");
    // console.log(window.TXTextControl.StreamType.WordprocessingML);
    // Delay of 2000 milliseconds (2 seconds) before calling handleFileUpload
    // setTimeout(() => {
    handleFileUpload(urldata);
    // }, 10000);
  }

  function SaveAndSendToAssigner(msg) {
    window.TXTextControl.saveDocument(window.TXTextControl.StreamType.WordprocessingML, function (e) {
      var base64Document = e.data;
      console.log("%o", base64Document);
      const buffer = Buffer.from(base64Document, "utf8");
      // console.log("from buffer : %o", buffer.toString("utf8"));
      let payload = {
        escrow_id: companyStore.escrowId,
        contract_id: companyStore.Contract_id,
        buffer: buffer,
      };
      console.log(payload);

      var formdata = new FormData();
      formdata.append("escrow_id", companyStore.escrowId);
      formdata.append("contract_id", companyStore.Contract_id);
      formdata.append("buffer", buffer);

      axios
        .post(`${config.mock_api_endpoint}/api/documentUpdated`, formdata, {
          headers: {
            "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
            // "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("res.data");
          setHide(true);
        })
        .catch((err) => {
          console.log(err);
        });
    });

    if (msg === "accept") {
      AcceptDoc();
    } else {
      setLoader(false);
      navigate("/content/contract/main");
    }
  }

  function MakeDocumentEditable() {
    window.TXTextControl.setEditMode(window.TXTextControl.EditMode.Edit);
    window.TXTextControl.setIsTrackChangesEnabled(true);

    console.log("Made Document Editable");
  }
  async function DownloadPDF() {
    window.TXTextControl.saveDocument(window.TXTextControl.StreamType.InternalUnicodeFormat, function (e) {
      var base64Document = e.data;
      //save the base64 string in the database.
      console.log("%o", base64Document);
      window.TXTextControl.saveDocument(window.TXTextControl.StreamType.AdobePDF, function (e) {
        console.log(e.data);
        const downloadLink = document.createElement("a");
        downloadLink.href = "data:application/pdf;base64," + e.data;
        downloadLink.download = "convertedPDFFile.pdf";
        downloadLink.click();
      });
    });

    // window.TXTextControl.save("AdobePDF",handleSave)
    // // TXTextControl.save(<StreamType> streamType, , [<SaveSettings> saveSettings], [<ErrorCallback> errorCallback])
  }

  useEffect(() => {
    if (authTokens && Object?.keys(authTokens)?.length > 0) {
      const obj = {
        escrow_id: companyStore.escrowId,
        contract_id: companyStore.Contract_id,
      };
      axios
        .post(`${config.mock_api_endpoint}/api/getContractData`, obj, {
          // headers: {
          //   aws_cognito_id_token: authTokens.idToken,
          //   aws_cognito_access_token: authTokens.accessToken,
          //   "Content-Type": "application/json",
          // },
          headers: {
            "x-encrypted-token": "a25b2963bab38ab7577575df9e80a27a",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log("data from contract data : %o", res.data.counter_parties);
          setcounter_parties(res.data.counter_parties);
          setContName(res.data.contract_name);
          setHideButton(res.data.status === "Accepted" ? true : false);
          res.data.counter_parties.forEach((detail) => {
            console.log("status-->", detail.status);
            if (detail.status == "Not Finalized") {
              setcomplete(false);
            }
          });
          // setUsers(res.data.counter_parties);
          // const initialActions = {};
          // res.data.counter_parties.forEach((user) => {
          //   initialActions[user._id] = user.access; // Use _id here
          // });
          // setSelectedActions(initialActions);
        })
        .catch((err) => {
          console.error("%o", err);
        });
    }
  }, [authTokens]);

  const savedDocument = () => {
    try {
      window.TXTextControl.saveDocument(window.TXTextControl.StreamType.AdobePDF, async function (e) {
        console.log("textControlData-->", e);

        const base64Pdf = e.data;
        const buffer = Buffer.from(base64Pdf, "utf8");

        // Prepare FormData
        const formData = new FormData();
        formData.append("escrow_id", companyStore.escrowId); // Replace with actual escrow_id
        formData.append("contract_id", companyStore.Contract_id); // Replace with actual contract_id
        formData.append("buffer", buffer);

        const response = await fetch(`${config.mock_api_endpoint}/api/contractToEsign`, {
          method: "POST",
          body: formData,
          headers: {
            aws_cognito_id_token: authTokens.idToken,
            aws_cognito_access_token: authTokens.accessToken,
            "Content-Type": "application/json",
          },
        });

        const result = await response.json();
        console.log("API Response:", result);
      });
    } catch (error) {
      console.error("Error processing document:", error);
    }
  };

  async function removeAllChanges(accept) {
    window.TXTextControl.trackedChanges.getCount((count) => {
      setLoader(true);
      if (count === 0) return;

      // recursively loop through all changes
      // and remove them
      window.TXTextControl.trackedChanges.elementAt(0, (element) => {
        window.TXTextControl.trackedChanges.remove(element, accept, (deleted) => {
          if (deleted === true) removeAllChanges(accept);
        });
      });
    });

    SaveAndSendToAssigner("accept");
  }

  const AcceptDoc = () => {
    // removeAllChanges(true)
    savedDocument();

    // accept all the doc
    let payload = {
      escrow_id: companyStore.escrowId,
      contract_id: companyStore.Contract_id,
      status: "Accepted",
    };
    axios
      .post(`${config.mock_api_endpoint}/api/documentFinalized`, payload, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("finalized", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setAcceptAllTriggered(false);
    counter_parties.forEach((data) => {
      console.log("ContractName", companyStore.ContractName, "data", data);
      let bodyHtml = `
      <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html lang="und" dir="auto" xmlns="http://www.w3.org/1999/xhtml">
    <head>
      <style type="text/css">
        @import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700");
      </style>
      <title></title>
      <!--[if (!mso)&(!ie)]>These<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>are<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>for<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>outlook<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>live<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>that<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>removes<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>the first<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>10 well-formed<!-- -->
      <!--<![endif]-->
      <!--[if (!mso)&(!ie)]>conditional comments<!-- -->
      <!--<![endif]-->
      <!--[if gte mso 9]>
        <xml>
          <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
            <o:AllowPNG />
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
      <![endif]-->
      <style type="text/css">
        .dark-mode .bg-fffffe {
          background-color: #FFFFFE !important;
        }
        .dark-mode .color-4c4e64 {
          color: #4C4E64 !important;
        }
        .dark-mode .color-777777 {
          color: #777777 !important;
        }
        @media (prefers-color-scheme: dark) {
          html:not(.light-mode) .bg-fffffe {
            background-color: #FFFFFE !important;
          }
          html:not(.light-mode) .color-4c4e64 {
            color: #4C4E64 !important;
          }
          html:not(.light-mode) .color-777777 {
            color: #777777 !important;
          }
        }
        [data-ogsc] .bg-fffffe {
          background-color: #FFFFFE !important;
        }
        [data-ogsc] .color-4c4e64 {
          color: #4C4E64 !important;
        }
        [data-ogsc] .color-777777 {
          color: #777777 !important;
        }
      </style>
      <meta name="color-scheme" content="light dark" />
      <meta name="supported-color-schemes" content="light dark" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <!--<![endif]-->
      <meta name="x-apple-disable-message-reformatting" />
      <style></style>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style type="text/css">
        u + div .kombai-email-compat__list-with-padding-left {
          padding-left: 0.5em !important;
        }
      </style>
      <!--[if mso]>
        <style type="text/css">
          v\:* {
            behavior: url(#default#VML);
            display: inline-block;
          }
          o\:* {
            behavior: url(#default#VML);
            display: inline-block;
          }
          w\:* {
            behavior: url(#default#VML);
            display: inline-block;
          }
          .ExternalClass {
            width: 100%;
          }
          table {
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
          }
          img {
            -ms-interpolation-mode: bicubic;
          }
          .ReadMsgBody {
            width: 100%;
          }
          a {
            background: transparent !important;
            background-color: transparent !important;
          }
          li {
            text-align: -webkit-match-parent;
            display: list-item;
            text-indent: -1em;
          }
          ul,
          ol {
            margin-left: 1em !important;
          }
          p {
            text-indent: 0;
          }
        </style>
      <![endif]-->
    </head>
    <body style="margin: 0; padding: 0">
      <div style="font-size: 0px; line-height: 1px; mso-line-height-rule: exactly; display: none; max-width: 0px; max-height: 0px; opacity: 0; overflow: hidden; mso-hide: all"></div>
      <center lang="und" dir="auto" style="width: 100%; table-layout: fixed; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%">
        <table class="bg-fffffe" cellpadding="0" cellspacing="0" border="0" role="presentation" bgcolor="white" width="657.00" style="border-radius: 12px; background-color: white; padding-top: 32px; padding-bottom: 31.16px; width: 657px; border-spacing: 0; font-family: Sarabun, Tahoma, sans-serif; min-width: 657px; border-collapse: separate">
          <tr>
            <td valign="middle" width="100.00%" style="width: 100%; vertical-align: middle">
              <table cellpadding="0" cellspacing="0" border="0" role="presentation" width="100.00%" style="width: 100%; border-spacing: 0">
                <tr>
                  <td align="center" style="padding-bottom: 13.76px">
                    <img src="assets/image_1fad596c.png" width="101" style="max-width: initial; width: 101px; display: block" />
                  </td>
                </tr>
                <tr>
                  <td align="left" style="padding-top: 13.76px; padding-bottom: 13.72px; padding-left: 32px">
                    <p class="color-4c4e64" width="100.00%" style="font-size: 20px; font-weight: 400; letter-spacing: 0.4px; color: #4C4E64; margin: 0; padding: 0; width: 100%; line-height: 21.44px; mso-line-height-rule: exactly"><span>Dear </span><span style="font-size: 20px; font-weight: 600; letter-spacing: 0.4px; text-align: left">${data.name},</span></p>
                  </td>
                </tr>
                <tr>
                  <td align="left" style="padding-top: 13.72px; padding-left: 32px; padding-right: 32px">
                    <div class="color-777777" width="100.00%" style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777; margin: 0; padding: 0; width: 100%">
                      <span>We are pleased to inform you that the contract titled </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">&quot;${contname} &quot;</span><span> has been finalized and all proposed changes from the negotiation process have been accepted by the Contract Admin. At this stage, no further edits can be made to the document.<br /><br /></span><span style="font-size: 20px; font-weight: 500; color: #333333; letter-spacing: 0.4px; text-align: left">Next Steps</span>
                      <ul class="kombai-email-compat__list-with-padding-left" style="list-style-type: disc; margin: 0; padding-left: 1.5em">
                        <li>You will shortly receive an email with instructions to complete the contract e-signing process.</li>
                      </ul>
                      <span><br /></span><span style="font-size: 20px; font-weight: 500; color: #333333; letter-spacing: 0.4px; text-align: left">Contract Details</span>
                      <ul class="kombai-email-compat__list-with-padding-left" style="list-style-type: disc; margin: 0; padding-left: 1.5em">
                        <li><span>Contract Name: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${contname}</span></li>
                        <li><span>Final Version Date: </span><span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left">${date.split("T")[0]}</span></li>
                      </ul>
                      <span style="font-size: 20px; font-weight: 700; color: #333333; letter-spacing: 0.4px; text-align: left"><br /></span><span>If you have any questions or need assistance during the e-sign process, please do not hesitate to contact us.<br /><br />Thank you for your participation, and we look forward to completing the agreement soon.<br /></span>
                    </div>
                  </td>
                </tr>
                  <tr>
              <td align="left" style="padding-top: 13.75px; padding-bottom: 12.15px; padding-left: 32px">
               <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777",>Best regards,</span>
                <br />
                <span style="font-size: 20px; font-weight: 500; letter-spacing: 0.4px; text-align: left; color: #777777;">Digital Onboarding Team</span>
            </td>
            </tr>
              </table>
            </td>
          </tr>
        </table>
      </center>
    </body>
  </html>
      `;

      const emailPayload = {
        to: data.email,
        body: bodyHtml,
        subject: "Final Contract Approved - Please Proceed to E-Sign",
      };

      axios
        .post(`${config.email_api_endpoint}/send-email`, emailPayload, {
          headers: { "Content-Type": "multipart/form-data", "x-api-key": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9" },
        })
        .then((res) => {
          setHideButton(true);
          setLoader(false);
          console.log(res.data);
        })
        .catch((err) => {
          console.log("", err);
        });
    });
  };
  return (
    <div className={style.container}>
      <Spin
        size="large"
        tip="Loading..."
        spinning={loader}
        className="d-flex justify-content-center align-items-center"
        wrapperClassName="spin-center-wrapper"
        style={{ maxHeight: "none" }}
      >
        {hideButton === true ? (
          <div className={style.btncontainer}>
            <button className={style.button} onClick={DownloadPDF}>
              Download
            </button>
          </div>
        ) : (
          <div className={style.btncontainer}>
            {Completed && (
              <button
                // className=
                className={`${style.button} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Maker" ? "" : style.disabledDiv}`}
                onClick={() => {
                  removeAllChanges(true);
                }}
              >
                Accept All
              </button>
            )}
            {!hide && (
              <button className={style.button} onClick={SaveAndSendToAssigner}>
                Save & Send
              </button>
            )}
            {!hide && (
              <button className={style.button} onClick={MakeDocumentEditable}>
                Make Changes
              </button>
            )}
            <button className={style.button} onClick={DownloadPDF}>
              Save And Download
            </button>

            {!hide && (
              <button
                className={style.button}
                onClick={() => {
                  setMakeEditor(true);
                  setClicked(true);
                }}
              >
                Make Editor
              </button>
            )}
          </div>
        )}
        <DocumentEditor
          className={"ribbonGroupTrackedChanges"}
          style={{ width: "100%", height: "70em" }}
          serviceURL="https://trial.dsserver.io"
          // serviceURL="http://192.168.0.95:3005/"
          onLoad={handleDocumentEditorLoad}
          // allowAdditionalUserNames={false}
          userNames={[companyStore.AdminEmail]}
          // contextMenusEnabled={false}
          //on-premise
          authSettings={{
            clientId: "dsserver.AKIaD0JiLu6kQw87CvpBtxIUp4jpxS06",
            clientSecret: "BDhgBaeFoCK0TuGEYFxu7zV5Z7pVbCpE",
          }}

          // local server
          // authSettings={{
          //   clientId: "dsserver.GfeRmv34X6QIDqbsrSdZSD0XHS4lEUAW",
          //   clientSecret: "DadL7eoET7uaExLDw3DtQBBQrnqAH6nk",
          // }}
        ></DocumentEditor>
      </Spin>
      <MakeEditor
        isVisible={clicked}
        makeEditor={makeEditor}
        onclose={() => {
          setClicked(false);
        }}
      />
    </div>
  );
}

export default Contractview;
