import PropTypes from "prop-types";
import styles from "./EscrowContainer.module.css";
import { Fragment, useEffect, useState } from "react";
import NewCompanyModel from "../KYCList/AddEscrow/NewCompanyModel";
import SelectActivity1 from "../SelectActivity/SelectActivity1";
import config from "../../../service/config";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Spin } from "antd";
import { Pagination } from "antd";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import companyStore from "../CompanyStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAllProduct } from "../../api-context/commonApiContext";

const PageContainer = ({ className = "" }) => {
  const [data, setData] = useState([]);
  const { authTokens,role,userData } = useAllProduct();
  const [showModel, setShowModel] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [currentcompid, setCurrentCompId] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [currentCompName, setCurrentCompName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  // const [role, setRole] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  useEffect(() => {
    const fetchData = async () => {
      if (authTokens && Object?.keys(authTokens)?.length > 0 ) {
        try {
          setLoader(true);

          // Fetch escrow data
          const escrowRes = await axios.get(`${config.node_api_endpoint}/v1/escrow`, {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              aws_cognito_access_token: authTokens.accessToken,
              "Content-Type": "application/json",
            },
          });
          const escrowData = escrowRes.data;

          // Fetch user data
          // const userRes = await axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
          //   headers: {
          //     aws_cognito_id_token: authTokens.idToken,
          //     aws_cognito_access_token: authTokens.accessToken,
          //     "Content-Type": "application/json",
          //   },
          // });
          // const users = userRes.data.users;

          // const getUserRole = users?.filter(
          //   (data) => data.email === companyStore.AdminEmail && data.status === "active",
          // );

          // if (getUserRole?.length === 0) {
          //   // localStorage.removeItem("authToken");
          //   console.log("Logged out");
          //   return navigate("/");
          // }
          // setRole(getUserRole[0].auth[0].role.map((data) => data).toString());

          // Map role to escrow data

          console.log("hey-->", userData);
          const updatedEscrowData = escrowData.map((escrow) => {
            const user = userData.find((u) => u.email === escrow.created_by);

            return {
              ...escrow,
              role: user ? user.auth[0].role : "N/A", // Default to 'N/A' if no match is found
            };
          });
          
          // Sort and set filtered data
          const sortedData = updatedEscrowData.sort((a, b) => {
            const dateA = dayjs(a.created_at, "DD MMM YYYY / hh:mm a");
            const dateB = dayjs(b.created_at, "DD MMM YYYY / hh:mm a");
            return dateB - dateA;
          });

          setData(updatedEscrowData);
          setFilteredData(sortedData);
          setLoader(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoader(false);
        }
      }
    };

    fetchData();
  }, [showModel, role, userData,authTokens]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item.created_at.toLowerCase().includes(value.toLowerCase()) ||
          item?.role[0]?.toLowerCase().includes(value.toLowerCase()) ||
          item?.created_by?.toLowerCase().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
    setCurrentPage(0); // Reset to the first page
  };
  const applyFilters = () => {
    let filtered = [...data];

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract the date part before '/'
        const itemDate = new Date(datePart).toDateString(); // Convert to a comparable date format
        const filterDate = new Date(createdDateFilter).toDateString();

        console.log("dateFilter--->", itemDate, filterDate);

        return itemDate === filterDate;
      });
    }

    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Calculate current page data
  const startIndex = (currentPage - 1) * pageSize;
  // const currentPageData = filteredData.slice(startIndex, startIndex + pageSize);
  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);
  console.log("addmin-->", role);

  return (
    <div className={`${styles.pageContainer} ${styles.family}  h-100 w-100`}>
      <Spin
        size="large"
        tip="Loading..."
        spinning={loader}
        className="d-flex justify-content-center align-items-center"
        wrapperClassName="spin-nested-loading"
        style={{ maxHeight: "none" }}
      >
        <div
          className={`${styles.newCompany_div} ${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" ? "" : styles.disabledDiv}`}
        >
          <button
            onClick={() => {
              setShowModel(true);
            }}
            className={styles.newCompany}
            disabled={
              role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin"
                ? false
                : true
            }
          >
            <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
            <b className={styles.addNewCompany}>Add Escrow account</b>
          </button>
        </div>

        <div className={`${styles.searchFiltersParent} mt-3 p-2 mb-3`}>
          <div className={styles.frameParent}>
            {/* MUI search for table */}
            <div className={styles.leadingIconParent}>
              <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
              <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
            </div>

            <div className={styles.mobileMenuParent}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Created Date"
                  value={createdDateFilter}
                  onChange={(newValue) => setCreatedDateFilter(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "12px",
                    },
                  }}
                />
              </LocalizationProvider>

              {/* mui filter for table */}
              {/* <div className={styles.mobileMenu}>
              <div className={styles.field}>
                <div className={styles.content}>
                  <div className={styles.floatingLabel}>
                    <div className={styles.label}>Status</div>
                  </div>
                  <div className={styles.input3}>
                    <div className={styles.input1}>Requested</div>
                    <img
                      className={styles.cursorIcon}
                      alt=""
                      src="/boxarrowindown3.svg"
                    />
                  </div>
                </div>
              </div>
              <img className={styles.mobileMenuChild} alt="hey" src="/arrow14.svg" />
            </div> */}

              {/* button to apply filter to table */}
              <div className={styles.loadsButtons1} onClick={applyFilters}>
                <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
                <b className={styles.addNewCompany}>Apply</b>
              </div>
            </div>

            {/* Frame Container ends */}
          </div>
        </div>

        <Paper>
          <TableContainer>
            <Table>
              <TableHead
                className={styles.tableBg}
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "16px",
                  },
                }}
              >
                <TableRow>
                  <TableCell align="center">Created Date</TableCell>
                  <TableCell align="center">Escrow Name</TableCell>
                  <TableCell align="center">Created By</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.length > 0 ? (
                  currentPageData.map((detail) => (
                    <TableRow key={detail._id} sx={{ "& .MuiTableCell-root": { padding: "8px" } }}>
                      <TableCell align="center">{detail.created_at}</TableCell>
                      <TableCell align="center" title={detail.name.length > 30 ? detail.name : ""}>
                        {detail.name.length > 30 ? detail.name.slice(0, 30) + "..." : detail.name}
                      </TableCell>
                      <TableCell align="center">{detail.created_by}</TableCell>
                      <TableCell align="center">{detail.role}</TableCell>
                      <TableCell align="center">
                        <Button
                          className={styles.change}
                          style={{ color: "#2348AB", textDecoration: "underline", textTransform: "lowercase" }}
                          onClick={() => {
                            handleScrollToTop();
                            setShowActivity(true);
                            setCurrentCompId(detail._id);
                            setCurrentCompName(detail.name);
                          }}
                        >
                          {"View More"}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Records Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            className={`${styles.tableBg}`}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>

        <SelectActivity1
          isVisible={showActivity}
          onclose={() => {
            setShowActivity(false);
          }}
          escrowId={currentcompid}
          escrowName={currentCompName}
        />
        <NewCompanyModel
          isVisible={showModel}
          onclose={() => {
            setShowModel(false);
          }}
        />
      </Spin>
    </div>
  );
};

PageContainer.propTypes = {
  className: PropTypes.string,
};

export default PageContainer;
