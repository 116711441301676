import React, { useState } from "react";
import styles from "./DeleteEsignRequest.module.css";
import axios from "axios";
import config from "../../../../service/config";
import { useAllProduct } from "../../../api-context/commonApiContext";
export default function DeleteEsignRequest({ isVisible, onclose, Docid, className = "" }) {
    const { authTokens } = useAllProduct();
  const handledelete = () => {
    let obj = {
      doc_id: Docid,
    };
    axios
      .post(`${config.node_api_endpoint}/v1/esign/deletedoc`, obj, {
        headers: {
          aws_cognito_id_token: authTokens.idToken,
          aws_cognito_access_token: authTokens.accessToken,
          // "Content-Type": "application/json",
        },
      })
      .then(() => {
        console.log("Esign Request has been Deleted");
        onclose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  if (!isVisible) return null;
  return (
    <div className={styles.newModelcontainer}>
      <div>
        <div className={styles.addCompany}>
          <div className={styles.newCompany}>Are you sure you want to delete the actions?</div>
          <div className={styles.mobileMenuParent}>
            <div className={styles.mobileMenu}>
              <img alt="" src="/deleteRequest.svg" />
            </div>
            <div className={styles.loadsButtonsParent}>
              <div
                className={styles.loadsButtons}
                onClick={() => {
                  onclose();
                }}
              >
                <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
                <b className={styles.loads}>No</b>
              </div>
              <div
                className={styles.loadsButtons1}
                onClick={() => {
                  handledelete();
                }}
              >
                <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown3.svg" />
                <b className={styles.loads}>Yes</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
