import PropTypes from "prop-types";
import styles from "./CompanyContainer.module.css";
import { Fragment, useEffect, useState } from "react";
import Axios from "axios";
import NewCompanyModel from "./AddCompany/NewCompanyModel";
import config from "../../../../service/config";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import companyStore from "../../CompanyStore";
import { Spin } from "antd";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Pagination } from "antd";
import { useAllProduct } from "../../../api-context/commonApiContext";
const PageContainer = ({ className = "" }) => {
  const { authTokens } = useAllProduct();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showModel, setShowModel] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [currentcompid, setCurrentCompId] = useState("");
  const [createdDateFilter, setCreatedDateFilter] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const [currentCompName, setCurrentCompName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [role, setRole] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  useEffect(() => {
    const fetchCompanyAndUserData = async () => {
      setLoader(true);
      try {
        if (Object.keys(authTokens).length > 0) {
          const payload = {
            escrow_id: companyStore.escrowId,
          };

          // Fetch company data
          const companyRes = await Axios.post(`${config.node_api_endpoint}/v2/company/list`, payload, {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              aws_cognito_access_token: authTokens.accessToken,
              "Content-Type": "application/json",
            },
          });
          const sortedCompanyData = companyRes.data.sort((a, b) => {
            const dateA = dayjs(a.created_at, "DD MMM YYYY / hh:mm a");
            const dateB = dayjs(b.created_at, "DD MMM YYYY / hh:mm a");
            return dateB - dateA;
          });

          // Fetch user data
          const userRes = await Axios.get(`${config.node_api_endpoint}/v1/user/getAllUserData`, {
            headers: {
              aws_cognito_id_token: authTokens.idToken,
              aws_cognito_access_token: authTokens.accessToken,
              "Content-Type": "application/json",
            },
          });
          const users = userRes.data.users;
          const getUserRole = users?.filter(
            (data) => data.email === companyStore.AdminEmail && data.status === "active",
          );
          setRole(getUserRole[0].auth[0].role.map((data) => data).toString());
          // Merge company data with user roles
          const mergedData = sortedCompanyData.map((company) => {
            const createdByUser = users.find((user) => user.email === company.created_by);

            return {
              ...company,
              role: createdByUser ? createdByUser.auth[0].role : "N/A", // Include role or fallback to "N/A"
            };
          });

          setData(mergedData);
          setFilteredData(mergedData);
        }
      } catch (error) {
        console.error("Error fetching company or user data:", error);
      } finally {
        setLoader(false);
      }
    };

    fetchCompanyAndUserData();
  }, [showModel, authTokens]);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const resetFilters = () => {
    setFilteredData(data);
  };

  const applyFilters = () => {
    let filtered = data;

    if (createdDateFilter) {
      filtered = filtered.filter((item) => {
        const [datePart] = item.created_at.split(" / "); // Extract date part
        const itemDate = dayjs(datePart, "DD MMM YYYY").format("YYYY-MM-DD"); // Parse and format
        const filterDate = dayjs(createdDateFilter).format("YYYY-MM-DD");

        console.log("Parsed itemDate:", itemDate, "Parsed filterDate:", filterDate);

        return itemDate === filterDate;
      });
    }

    if (textFilter) {
      filtered = filtered.filter((item) => item.status === textFilter);
    }

    setFilteredData(filtered);
    setCurrentPage(0);
  };

  const handleTextFilterChange = (e) => {
    const value = e.target.value;
    setTextFilter(value);

    let filtered = data;

    if (value) {
      filtered = filtered.filter(
        (item) =>
          item.created_at.toLowerCase().includes(value.toLowerCase()) ||
          item.created_by.toLowerCase().includes(value.toLowerCase()) ||
          item?.role[0]?.toLowerCase().includes(value.toLowerCase()) ||
          item.name.toLowerCase().includes(value.toLowerCase()),
      );
    }

    setFilteredData(filtered);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const startIndex = (currentPage - 1) * pageSize;
  // const currentPageData = filteredData.slice(startIndex, startIndex + pageSize);
  const currentPageData = filteredData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

  return (
    <>
      <div className={`${styles.pageContainer} ${styles.family} h-100 w-100`}>
        <Spin
          size="large"
          tip="Loading..."
          spinning={loader}
          className="d-flex justify-content-center align-items-center"
          style={{ maxHeight: "none" }}
        >
          <div className={styles.newCompany_div}>
            <b className={styles.escrowName}>{companyStore.escrowName}</b>
            <div
              className={`${role.toString() === "Owner" || role.toString() === "Super Admin" || role.toString() === "Admin" || role.toString() === "Maker" ? "" : styles.disabledDiv}`}
            >
              <button
                onClick={() => {
                  setShowModel(true);
                }}
                className={styles.newCompany}
                disabled={
                  role.toString() === "Owner" ||
                  role.toString() === "Super Admin" ||
                  role.toString() === "Admin" ||
                  role.toString() === "Maker"
                    ? false
                    : true
                }
              >
                <img className={styles.plusCircleIcon} alt="" src="/pluscircle.svg" />
                <b className={`${styles.addNewCompany}`}>New Company</b>
              </button>
            </div>
          </div>

          <div className={`${styles.searchFiltersParent} mt-2 mb-3 p-2`}>
            <div className={styles.frameParent}>
              <div className={styles.leadingIconParent}>
                <img className={styles.leadingIcon} alt="" src="/leadingicon.svg" />
                <TextField label="Search" variant="standard" value={textFilter} onChange={handleTextFilterChange} />
              </div>

              <div className={styles.mobileMenuParent}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Created Date"
                    value={createdDateFilter}
                    onChange={(newValue) => setCreatedDateFilter(newValue)}
                    renderInput={(params) => <TextField {...params} />}
                    sx={{
                      "& .MuiInputBase-root": {
                        width: "250px",
                        borderRadius: "12px",
                      },
                    }}
                  />
                </LocalizationProvider>

                <div className={styles.loadsButtons1} onClick={applyFilters}>
                  <img className={styles.boxArrowInDownIcon} alt="" src="/boxarrowindown1.svg" />
                  <b className={styles.addNewCompany}>Apply</b>
                </div>
              </div>

              {/* Frame Container ends */}
            </div>
          </div>
          <div className={`${`${styles.tableBg} d-flex justify-content-between px-2`}`}>
            <h2 className={styles.title}>Company List</h2>
          </div>
          <Paper>
            <TableContainer>
              <Table>
                <TableHead
                  className={styles.tableBg}
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: "16px",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell align="center">Created Date</TableCell>
                    <TableCell align="center">Company Name</TableCell>
                    <TableCell align="center">Created By</TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.length > 0 ? (
                    currentPageData.map((detail) => (
                      <TableRow key={detail._id} sx={{ "& .MuiTableCell-root": { padding: "8px" } }}>
                        <TableCell align="center">{detail.created_at}</TableCell>
                        <TableCell align="center" title={detail.name.length > 30 ? detail.name : ""}>
                          {detail.name.length > 30 ? detail.name.slice(0, 30) + "..." : detail.name}
                        </TableCell>
                        <TableCell align="center">{detail.created_by}</TableCell>
                        <TableCell align="center">{detail.role}</TableCell>
                        <TableCell align="center">
                          <Button
                            className={styles.change}
                            style={{ color: "#2348AB", textDecoration: "underline", textTransform: "lowercase" }}
                            onClick={() => {
                              handleScrollToTop();
                              setCurrentCompId(detail._id);
                              setCurrentCompName(detail.name);
                              companyStore.setCompanyID(detail._id);
                              companyStore.setCompanyName(detail.name);
                              console.log(detail._id);
                              navigate("/content/company/kyclist/main");
                            }}
                          >
                            {"View More"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No Records Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className={`${styles.tableBg}`}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <NewCompanyModel
            isVisible={showModel}
            setShowModel={setShowModel}
            onclose={() => {
              setShowModel(false);
            }}
          />
        </Spin>
      </div>
    </>
  );
};

PageContainer.propTypes = {
  className: PropTypes.string,
};

export default PageContainer;
